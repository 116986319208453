import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Box, LinearProgress, Typography } from '@material-ui/core';
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      position: 'absolute',
      top: '8px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '5px 10px',
      borderRadius: '4px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    boxLast: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    title: {
      display: "block",
      height: 32,
      fontSize: 14,
    },
    value: {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: 150,
    },
  })
);

const MissionSummary = ({
  missionSummaryData,
}) => {
  const classes = useStyles();
  const missionAchievedRatio = missionSummaryData.countAchieved / missionSummaryData.startedNumber * 100;
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <Grid container spacing={1}>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >総ミッション数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.startedNumber !== undefined
                      ? `${missionSummaryData.startedNumber}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >達成ミッション数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.countAchieved !== undefined
                      ? `${missionSummaryData.countAchieved} (${missionAchievedRatio.toFixed(2)}%)`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >達成件数/達成者数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.totalAchievedCount !== undefined
                      ? `${missionSummaryData.totalAchievedCount} / ${missionSummaryData.countAchievers}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >参加件数/参加者数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.totalAchievedAndNonAchievedCount !== undefined
                      ? `${missionSummaryData.totalAchievedAndNonAchievedCount} / ${missionSummaryData.totalPlayerCount}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >総撮影枚数/総撮影基数</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.totalPicCount !== undefined
                      ? `${H.FormatNumStrHelper.formatCommaThousand(missionSummaryData.totalPicCount)} /
                         ${missionSummaryData.totalAssetCount}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item sm={6} md={4} lg={2}>
            <div className={classes.box}>
              <Grid container direction={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant="caption"
                    className={classes.title}
                  >初回達成の平均所要時間</Typography>
                </Grid>
                <Grid item xs={1} sm={6} md={4}>
                  <Typography variant="h6" className={classes.value}>
                    {missionSummaryData.avgMinutesTillFirstAchieve !== undefined
                      ? `${missionSummaryData.avgMinutesTillFirstAchieve}分`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MissionSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  missionSummaryData: state.pictree.missionSummaryData,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionSummary));
