import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import clsx from "clsx";
import { Switch, Route } from "react-router-dom";
import { Drawer, Divider, IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";
import { Workspace, SidebarWrapper, Header2 } from "components";
import NftDepTxFilter from "components/Filter/NftDepTxFilter";
import InGameItemTxFilter from "components/Filter/InGameItemTxFilter";
import UsersByAdFilter from "components/Filter/UsersByAdFilter";
import NftDepLowTxFilter from "components/Filter/NftDepLowTxFilter";
import DashboardStyles from "containers/DashboardStyles";
import RoutesDashboard from "routes/RoutesDashboard";
import RoutesDenchu from "routes/RoutesDenchu";
import { setFilterDrawerState } from "actions";

function resizeDispatch() {
  if (typeof Event === "function") {
    window.dispatchEvent(new Event("resize"));
  } else {
    const evt = window.document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  }
}
class DashboardContainer extends Component {
  state = {
    opened: true,
    username: "",
  };

  handleDrawerToggle = (type) => {
    this.setState({ opened: type === "submenu" ? true : !this.state.opened });
    resizeDispatch();
  };

  handleFullscreenToggle = () => {
    const element = document.querySelector("#root");
    const isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;
    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  componentDidMount() {
    // this.unlisten = this.props.history.listen(() => {
    //     document.querySelector('#root > div > div > main').scrollTop = 0;
    // });
    // this.props.history.push("/signin");
    if (window.location.pathname == "/home") {
      const accessKeyValue = localStorage.getItem("access_key");
      if (accessKeyValue !== undefined) {
        switch (accessKeyValue) {
          case "WPZssCHh3jDp8bwIKhrwA7Rk89PN":
            this.props.history.push(
              "/game/luckyfarmer/marketplace-sale/overview"
            );
            break;
          case "qjoDw9xh16E5giTPmhhByywmaLfN":
            this.props.history.push(
              "/game/lostarchiveplus/marketplace-sale/overview"
            );
            break;
          case "r6pjc6qlvnw4znji7nzkwjx2wag8":
            this.props.history.push("/game/dragonramen/overview");
            break;
          case "EqOsAVMYK4pqtSnVDgz67zVKUXFy":
            this.props.history.push(
              "/game/graffitiracer/marketplace-sale/overview"
            );
            break;
          case "cRaANwUa68M3s0N0oF4U6kiADDli":
            this.props.history.push(
              "/game/roguerollrulers/marketplace-sale/overview"
            );
            break;
          case "lUF2uNRqBJO7EUv9zNnA3wloxxPF":
            this.props.history.push(
              "/game/bouncybunny/marketplace-sale/overview"
            );
            break;
          case "MHoNNpheuCs67M80p6IB7MFCe9i8":
            this.props.history.push(
              "/game/pictree/home"
            );
            window.location.reload(true);
            break;
          default:
        }
      }
    }
  }

  componentWillUnmount() {
    // this.unlisten();
  }

  getRouteItems() {
    // SUPERUSER DeRKXQzmsg9YMY4NrDZ8aqxp4yJh, PicTree: MHoNNpheuCs67M80p6IB7MFCe9i8
    let returnMenu = RoutesDashboard.items;
    let accessKeyValue =
      localStorage.getItem("access_key") !== null
        ? localStorage.getItem("access_key")
        : this.props.accessKey;
    if (process.env.REACT_APP_SUPERUSER !== accessKeyValue) {
      if (accessKeyValue === "MHoNNpheuCs67M80p6IB7MFCe9i8") {
        returnMenu = RoutesDenchu.items;
      } else {
        returnMenu = [];
        for (let i = 0; i < RoutesDashboard.items.length; i++) {
          // if (RoutesDashboard.items[i]['agent']) {
          //     returnMenu.push(RoutesDashboard.items[i]);
          // }
          if (RoutesDashboard.items[i]["vendor"] == accessKeyValue) {
            returnMenu.push(RoutesDashboard.items[i]);
          }
        }
      }
    }
    return returnMenu;
  }

  render() {
    const { classes, currentPage, filterDrawerState, setFilterDrawerState } =
      this.props;
    const { opened } = this.state;
    const routesItems = this.getRouteItems();

    const handleDrawerClose = () => {
      setFilterDrawerState(!filterDrawerState ? currentPage : false);
    };

    const getRoutes = (
      <Switch>
        {/* { routesItems.map((item, index) => (
                    <Route exact path={item.path} component={item.component} name={item.name} key={index} />
                ))} */}
        {/* <Route exact path='/profile' component={Profile} name='Profile' /> */}
        {routesItems.map((item, index) =>
          item.type === "external" ? (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          ) : item.type === "submenu" ? (
            item.children.map((subItem) =>
              subItem.type === "submenu" ? (
                subItem.children.map((subsubItem) => (
                  <Route
                    exact
                    path={`${item.path}${subItem.path}${subsubItem.path}`}
                    component={subsubItem.component}
                    name={subsubItem.name}
                  />
                ))
              ) : (
                subItem.disabled ?
                null :
                <Route
                  exact
                  path={`${item.path}${subItem.path}`}
                  component={subItem.component}
                  name={subItem.name}
                />
              )
            )
          ) : (
            <Route
              exact
              path={item.path}
              component={item.component}
              name={item.name}
              key={index}
            />
          )
        )}
      </Switch>
    );

    return (
      <Fragment>
        <Header2
          logoAltText="Digital Entertainment Asset Admin"
          logo={`/static/images/logo35h.png`}
          toggleDrawer={this.handleDrawerToggle}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          currentLocation={this.props.location.pathname}
        />
        <div className={classNames(classes.panel, "theme-dark")}>
          <SidebarWrapper
            routes={routesItems}
            opened={opened}
            toggleDrawer={this.handleDrawerToggle}
            toggleFullscreen={this.handleFullscreenToggle}
            handleLogout={this.props.handleLogout}
          />
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: filterDrawerState,
            })}
          >
            <Workspace
              opened={opened}
              currentLocation={this.props.location.pathname}
            >
              {getRoutes}
            </Workspace>
          </div>
          <div id="page-data"></div>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={filterDrawerState ? true : false}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <div className={classes.drawerContent}>
              {currentPage === "/nftdep/user-ban" && <NftDepLowTxFilter />}
              {currentPage === "/nftdep/nft-transaction-history" && (
                <NftDepTxFilter property="nft" />
              )}
              {currentPage === "/users/list-by-adcode" && <UsersByAdFilter />}
              {currentPage.indexOf("ingame-sale/transaction-history") !== -1
              ? <InGameItemTxFilter property="item" />
              : null}
            </div>
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

DashboardContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accessKey: state.page.accessKey,
  filterDrawerState: state.page.filterDrawerState,
  currentPage: state.page.currentPage,
});

const mapDispatchToProps = (dispatch) => ({
  setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyles, { withTheme: true })(DashboardContainer));
