import { AFFILIATE, CAMPAIGN, USERS, MAP, HOME, PAGE, NFTDEP, COOKINBURGER, DRAGONRAMEN, LUCKYFARMER,
    GRAFITTIRACER, KAMUIVERSE, JTCB, GA, PUZZLE, PUZZLE_GEO, DATA, DATA_LOAD, KPI, LOSTARCHIVE, SOULFUSERS, PICTREE, A2E } from '../constants';

// Affiliate Create
export const setCreateStatus = (filter, value) => ({
    type: AFFILIATE.SET_CREATE_STATUS,
    filter: filter,
    value: value
});

export const setCampaignName = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_NAME,
    payload: param
});

export const setCampaignEmail = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_EMAIL,
    payload: param
});

export const setCampaignType = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_TYPE,
    payload: param
});

export const setCampaignGame = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_GAME,
    payload: param
});

export const getCampaignGroup = (param) => ({
    type: AFFILIATE.GET_AFFILIATOR_GROUP,
    payload: param
});

export const setCampaignGroup = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_GROUP,
    payload: param
});

export const getCampaignGroupSelected = (param) => ({
    type: AFFILIATE.GET_AFFILIATOR_GROUP_SELECTED,
    payload: param
});

export const setCampaignGroupSelected = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_GROUP_SELECTED,
    payload: param
});

export const setAffiliatorFT = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_FT,
    payload: param
});

export const setAffiliatorReg = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_REG,
    payload: param
});

export const setAffiliatorConv = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_CONV,
    payload: param
});

export const setCampaignMedia = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_MEDIA,
    payload: param
});

export const setAffiliatorCode = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR_CODE,
    payload: param
});

export const setUsersListData = (param) => ({
    type: USERS.SET_USERS_LIST_DATA,
    payload: param
});

export const setUsersRegListData = (param) => ({
    type: USERS.SET_USERS_REG_LIST_DATA,
    payload: param
});

export const setUsersGameDirecListData = (param) => ({
    type: USERS.SET_USERS_GAME_DIRECT_LIST_DATA,
    payload: param
});

export const setUserCsvData = (param) => ({
    type: USERS.SET_USER_CSV_DATA,
    payload: param,
});

export const setUserCsvDownloader = (param) => ({
    type: USERS.SET_USER_CSV_DOWNLOADER,
    payload: param,
});

export const getUserCsvData = () => ({
    type: USERS.GET_USER_CSV_DATA,
});

export const setCreateBtnState = (param) => ({
    type: AFFILIATE.SET_CREATE_BTN_STATE,
    payload: param
});

export const setAffiliator = (param) => ({
    type: AFFILIATE.SET_AFFILIATOR,
    payload: param
});

export const updateAffiliator = (param) => ({
    type: AFFILIATE.UPDATE_AFFILIATOR,
    payload: param
});

export const setUpdateAffiliatorState = (param) => ({
    type: AFFILIATE.SET_UPDATE_AFFILIATE_STATE,
    payload: param
});

export const getAffiliateListDataByKey = (param) => ({
    type: AFFILIATE.GET_AFFILIATE_LIST_DATA_BY_KEY,
    payload: param
});

export const setActiveAffiliateNumber = (param) => ({
    type: AFFILIATE.SET_ACTIVE_AFFILIATE_NUMBER,
    payload: param
});

export const deleteAffiliate = (param) => ({
    type: AFFILIATE.DELETE_AFFILIATE,
    payload: param
});

export const updateAffiliateListData = (param) => ({
    type: AFFILIATE.UPDATE_AFFILIATE_LIST_DATA,
    payload: param
});

export const setAffiliatorsData = (param) => ({
    type: AFFILIATE.SET_AFFILIATE_LIST_DATA,
    payload: param
})

export const setAffiliateIdToUpdate = (param) => ({
    type: AFFILIATE.SET_AFFILIATE_ID_TO_UPDATE,
    payload: param
});

export const clearAffiliateFields = (param) => ({
    type: AFFILIATE.CLEAR_AFFILIATE_FIELDS,
    payload: param
});

export const setSubmitAffiliate = (param) => ({
    type: AFFILIATE.SET_SUBMIT_AFFILIATE,
    payload: param
});

export const setSummaryDataFetchState = (param) => ({
    type: AFFILIATE.SET_SUMMARY_DATA_FETCH_STATE,
    payload: param
});

export const setLastUpdateDateTime = (param) => ({
    type: AFFILIATE.SET_LAST_UPDATE_DATE_TIME,
    payload: param
});

export const getTrendDataByAid = (param) => ({
    type: AFFILIATE.GET_TREND_DATA_BY_AID,
    payload: param
});

export const setTrendChartData = (param) => ({
    type: AFFILIATE.SET_TREND_CHART_DATA,
    payload: param
});

export const setNoTrendData = (param) => ({
    type: AFFILIATE.SET_NO_TREND_DATA,
    payload: param
});

export const setLoadingTrendData = (param) => ({
    type: AFFILIATE.SET_LOADING_TREND_DATA,
    payload: param
});

export const setAllBIListUsers = (param) => ({
    type: AFFILIATE.SET_ALL_BI_USERS_LIST,
    payload: param
});

// Campaign
export const getCampaignListDataByKey = (param) => ({
    type: CAMPAIGN.GET_CAMPAIGN_LIST_DATA_BY_KEY,
    payload: param
});

export const setCampaignListData = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_LIST_DATA,
    payload: param
});

export const setCampaignDataLoadById = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_DATA_LOAD_BY_ID,
    payload: param
});

export const setCampaignIdToFetch = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_ID_TO_FETCH,
    payload: param
});

export const getCampaignStatById = (param) => ({
    type: CAMPAIGN.GET_CAMPAIGN_STAT_DATA_BY_ID,
    payload: param
});

export const setCampaignStatById = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_STAT_BY_ID,
    payload: param
});

export const setCampaignTrendFetchRid = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_TREND_FETCH_RID,
    payload: param
});

export const getTrendFirstTimeByRid = (param) => ({
    type: CAMPAIGN.GET_TREND_FIRST_TIME_BY_RID,
    payload: param
});

export const setTrendFirstTimeByRid = (param) => ({
    type: CAMPAIGN.SET_TREND_FIRST_TIME_BY_RID,
    payload: param
});

export const setTrendGameVisitByRid = (param) => ({
    type: CAMPAIGN.SET_TREND_GAME_VISIT_BY_RID,
    payload: param
});

export const setTrendRegistByRid = (param) => ({
    type: CAMPAIGN.SET_TREND_REGIST_BY_RID,
    payload: param
});

export const setCampaignTrendDataLoadByRid = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_TREND_DATA_LOADING_BY_RID,
    payload: param
});

export const setCampaignTrendChartData = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_TREND_CHART_DATA,
    payload: param
});

export const getCampaignSummaryDataByDates = () => ({
    type: CAMPAIGN.GET_CAMPAIGN_SUMMARY_DATA_BY_DATES,
});

export const setCampaignDateRange = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_DATE_RANGE,
    payload: param
});

export const setPreDefinedCampaignDateRange = (param) => ({
    type: CAMPAIGN.SET_PREDEFINED_CAMPAIGN_DATE_RANGE,
    payload: param
});

export const setCampaignViewStats = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_VIEW_STATS,
    payload: param
});

export const setCampaignSelectedStats = (param) => ({
    type: CAMPAIGN.SET_CAMPAIGN_SELECTED_STATS,
    payload: param
});

// Users
export const getUserPageListData = () => ({
    type: USERS.GET_USER_PAGE_LIST_DATA
})

export const getUserPageListTotalNum = () => ({
    type: USERS.GET_USER_PAGE_LIST_TOTAL_NUM,
});

export const setUserPageListTotalNum = (param) => ({
    type: USERS.SET_USER_PAGE_LIST_PAGINATION,
    payload: param
});

export const setUserPageListPagination = (param) => ({
    type: USERS.SET_USER_PAGE_LIST_PAGINATION,
    payload: param
});

export const setUserPageListData = (param) => ({
    type: USERS.SET_USER_PAGE_LIST_DATA,
    payload: param
});

export const setUserPageTableLoading = (param) => ({
    type: USERS.SET_USER_PAGE_TABLE_LOADING,
    payload: param
});

export const setUserPageTableTotal = (param) => ({
    type: USERS.SET_USER_PAGE_TABLE_TOTAL,
    payload: param
});

export const getUsersDataByAid = (param) => ({
    type: USERS.GET_USERS_DATA_BY_AID,
    payload: param
});

export const setNoUsersData = (param) => ({
    type: USERS.SET_NO_USER_DATA,
    payload: param
});

export const clearUsersDataStored = () => ({
    type: USERS.CLEAR_USERS_DATA_STORED,
})

export const getRegDataByAid = (param) => ({
    type: USERS.GET_REG_DATA_BY_AID,
    payload: param
});

export const clearUsersRegListData = () => ({
    type: USERS.CLEAR_USERS_REG_LIST_DATA,
})

export const clearUsersGameDirectListData = () => ({
    type: USERS.CLEAR_USERS_GAME_DIRECT_LIST_DATA,
})

export const handleUsersFilter = (param) => ({
    type: USERS.HANDLE_USERS_FILTER,
    payload: param,
});

export const setUsersFilter = (param) => ({
    type: USERS.SET_USERS_FILTER,
    payload: param,
});

export const fetchUsersByFilter = () => ({
    type: USERS.FETCH_USERS_BY_FILTER,
});

export const setUserCountByFiltered = (param) => ({
    type: USERS.SET_USER_COUNT_FILTERED,
    payload: param,
});

export const setUserFilterQuery = (param) => ({
    type: USERS.SET_USER_FILTER_QUERY,
    payload: param,
});

export const setUserFilterCampaigns = (param) => ({
    type: USERS.SET_USER_FILTER_CAMPAIGNS,
    payload: param,
});

export const setUserPageNumber = (param) => ({
    type: USERS.SET_USER_PAGE_NUMBER,
    payload: param,
});

export const getUserListData = (param) => ({
    type: USERS.GET_USER_LIST_DATA,
    payload: param,
});

export const getUserListDataByTerm = (param) => ({
    type: USERS.GET_USER_LIST_DATA_BY_TERM,
    payload: param,
});

export const setUsersStatData = (param) => ({
    type: USERS.SET_USERS_STAT_DATA,
    payload: param,
});

export const setUserPageListDataFilter = (param) => ({
    type: USERS.SET_USER_PAGE_LIST_DATA_FILTER,
    payload: param,
});

// Page
export const setCurrentPage = (param) => ({
    type: PAGE.SET_CURRENT_PAGE,
    payload: param
});

export const setPageTopLoader = (param) => ({
    type: PAGE.SET_PAGE_TOP_LOADER,
    payload: param
});

export const setTableRowSelected = (param) => ({
    type: PAGE.SET_TABLE_ROW_SELECTED,
    payload: param
});

export const setIdToken = (param) => ({
    type: PAGE.SET_ID_TOKEN,
    payload: param
});

export const setAccessKey = (param) => ({
    type: PAGE.SET_ACCESS_KEY,
    payload: param
});

export const getDateRange = (param) => ({
    type: PAGE.GET_DATE_RANGE,
    payload: param
});

export const setDateRange = (param) => ({
    type: PAGE.SET_DATE_RANGE,
    payload: param
});

export const getDateRangePicTree = (param) => ({
    type: PAGE.GET_DATE_RANGE_PICTREE,
    payload: param
});

export const setDateRangePicTree = (param) => ({
    type: PAGE.SET_DATE_RANGE_PICTREE,
    payload: param
});

export const setDateRangePicTreeMission = (param) => ({
    type: PAGE.SET_DATE_RANGE_PICTREE_MISSION,
    payload: param
});

export const setMauDateRange = (param) => ({
    type: PAGE.SET_MAU_DATE_RANGE,
    payload: param
});

export const setMauPrevDateRange = (param) => ({
    type: PAGE.SET_MAU_PREV_DATE_RANGE,
    payload: param
});

export const setWauDateRange = (param) => ({
    type: PAGE.SET_WAU_DATE_RANGE,
    payload: param
});

export const setWauPrevDateRange = (param) => ({
    type: PAGE.SET_WAU_PREV_DATE_RANGE,
    payload: param
});

export const getHomePageData = () => ({
    type: PAGE.GET_HOME_PAGE_DATA,
});

export const getPuzzlePageData = () => ({
    type: PAGE.GET_PUZZLE_PAGE_DATA,
});

export const getComparisonDateDiff = (param) => ({
    type: PAGE.GET_COMPARISON_DATE_DIFF,
    payload: param
});

export const setComparisonDateDiff = (param) => ({
    type: PAGE.SET_COMPARISON_DATE_DIFF,
    payload: param
});

export const setComparisonDateDiffDisplay = (param) => ({
    type: PAGE.SET_COMPARISON_DATE_DIFF_DISPLAY,
    payload: param
});

export const getPrevDateRange = (param) => ({
    type: PAGE.GET_PREV_DATE_RANGE,
    payload: param
});

export const setPrevDateRange = (param) => ({
    type: PAGE.SET_PREV_DATE_RANGE,
    payload: param
});

export const getPrevDateRangePicTree = (param) => ({
    type: PAGE.GET_PREV_DATE_RANGE_PICTREE,
    payload: param
});

export const setPrevDateRangePicTree = (param) => ({
    type: PAGE.SET_PREV_DATE_RANGE_PICTREE,
    payload: param
});

export const setBtnStateDateFetch = (param) => ({
    type: PAGE.SET_BTN_STATE_DATA_FETCH,
    payload: param
});

export const setDepPrice = (param) => ({
    type: PAGE.SET_DEP_PRICE,
    payload: param
});

export const getDepPrice = (param) => ({
    type: PAGE.GET_DEP_PRICE,
});

export const setCurrency = (param) => ({
    type: PAGE.SET_CURRENCY,
    payload: param
});

export const setFilterDrawerState = (param) => ({
    type: PAGE.SET_FILTER_DRAWER_STATE,
    payload: param
});


// GA
export const fetchAllEventDataGA = () => ({
    type: GA.GET_ALL_EVENT_DATA,
})

// Home
export const getHomeTotalStatsData = (param) => ({
    type: HOME.GET_HOME_TOTAL_STATS_DATA,
    payload: param
});

export const setHomeTotalStatsData = (param) => ({
    type: HOME.SET_HOME_TOTAL_STATS_DATA,
    payload: param
});

export const setHomeTotalStatsByXDate = (param) => ({
    type: HOME.SET_HOME_TOTAL_STATS_BY_X_DATE,
    payload: param
});

export const getHomeAcquisitionData = () => ({
    type: HOME.GET_HOME_ACQUISITION_DATA,
});

export const setHomeAcquisitionData = (param) => ({
    type: HOME.SET_HOME_ACQUISITION_DATA,
    payload: param
});

export const getHomeAcquisitionPrevData = () => ({
    type: HOME.GET_HOME_ACQUISITION_PREV_DATA,
});

export const setHomeAcquisitionPrevData = (param) => ({
    type: HOME.SET_HOME_ACQUISITION_PREV_DATA,
    payload: param
});

export const getHomeActiveUsersData = () => ({
    type: HOME.GET_HOME_ACTIVE_USERS_DATA,
});

export const setHomeActiveUsersData = (param) => ({
    type: HOME.SET_HOME_ACTIVE_USERS_DATA,
    payload: param
});

export const setHomeActiveUsersGeoData = (param) => ({
    type: HOME.SET_HOME_ACTIVE_USERS_GEO_DATA,
    payload: param
});

export const setHomeActiveUsersGeoChartData = (param) => ({
    type: HOME.SET_HOME_ACTIVE_USERS_GEO_CHART_DATA,
    payload: param
});

export const setHomeAcquisitionLastTrendData = (param) => ({
    type: HOME.SET_HOME_ACQUISITION_LAST_TREND_DATA,
    payload: param
});

export const getHomeTierData = () => ({
    type: HOME.GET_HOME_TIER_DATA,
});

export const setHomeTierData = (param) => ({
    type: HOME.SET_HOME_TIER_DATA,
    payload: param
});

export const setHomeTierPrevData = (param) => ({
    type: HOME.SET_HOME_TIER_PREV_DATA,
    payload: param
});

export const setHomeTierTrendData = (param) => ({
    type: HOME.SET_HOME_TIER_TREND_DATA,
    payload: param,
})

export const getHomeNftHolderData = () => ({
    type: HOME.GET_HOME_NFT_HOLDER_DATA,
});

export const setHomeNftHolderData = (param) => ({
    type: HOME.SET_HOME_NFT_HOLDER_DATA,
    payload: param
});

// Soul Fusers
export const setSoulFusersNftTrendDataLoading = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setSoulFusersNftTrendDateRange = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getSoulFusersNftTrendPageData = () => ({
    type: SOULFUSERS.GET_SOULFUSERS_NFT_TREND_PAGE_DATA,
});

export const setSoulFusersNftTrendStoreAllFiat = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

export const setSoulFusersNftTrendDataCountAuction = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setSoulFusersNftTrendDataAmountAuction = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setSoulFusersNftTrendDataCountStore = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setSoulFusersNftTrendDataAmountStore = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setSoulFusersNftTrendDataCountStoreFiat = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setSoulFusersNftTrendAuctionAll = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setSoulFusersNftTrendStoreAll = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setSoulFusersNftTrendDataAmountStoreFiat = (param) => ({
    type: SOULFUSERS.SET_SOULFUSERS_NFT_TREND_STORE_ALL,
    payload: param,
});

// LOST ARCHIVE +
export const setLostArchiveNftTrendDataLoading = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setLostArchiveNftTrendDateRange = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getLostArchiveNftTrendPageData = () => ({
    type: LOSTARCHIVE.GET_LOSTARCHIVE_NFT_TREND_PAGE_DATA,
});

export const setLostArchiverNftTrendPageData = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setLostArchiveNftTrendDataCountAuction = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setLostArchiveNftTrendDataAmountAuction = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setLostArchiveNftTrendDataCountStore = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setLostArchiveNftTrendDataAmountStore = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setLostArchiveNftTrendDataCountStoreFiat = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setLostArchiveNftTrendDataAmountStoreFiat = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE_FIAT,
    payload: param,
});

export const setLostArchiveNftTrendAuctionAll = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setLostArchiveNftTrendStoreAll = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setLostArchiveNftTrendStoreAllFiat = (param) => ({
    type: LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

// LUCKY FARMER
export const setLuckyFarmerNftTrendDataLoading = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setLuckyFarmerNftTrendDateRange = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getLuckyFarmerNftTrendPageData = () => ({
    type: LUCKYFARMER.GET_LUCKYFARMER_NFT_TREND_PAGE_DATA,
});

export const setLuckyFarmerNftTrendPageData = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setLuckyFarmerNftTrendDataCountAuction = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setLuckyFarmerNftTrendDataAmountAuction = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setLuckyFarmerNftTrendDataCountStore = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setLuckyFarmerNftTrendDataAmountStore = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setLuckyFarmerNftTrendDataCountStoreFiat = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setLuckyFarmerNftTrendDataAmountStoreFiat = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_AMOUNT_STORE_FIAT,
    payload: param,
});

export const setLuckyFarmerGameTrendDataLoading = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_DATA_LOADING,
    payload: param,
});

export const setLuckyFarmeGameTrendDateRange = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_DATE_RANGE,
    payload: param,
});

export const getLuckyFarmerGameTrendPageData = () => ({
    type: LUCKYFARMER.GET_LUCKYFARMER_GAME_TREND_PAGE_DATA,
});

export const setLuckyFarmerGameTrendPageData = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_PAGE_DATA,
    payload: param,
});

export const setLuckyFarmerGameTrendDataStamina = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_STAMINA,
    payload: param,
});

export const setLuckyFarmerGameTrendDataLand = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_LAND,
    payload: param,
});

export const setLuckyFarmerGameTrendDataShop = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_SHOP,
    payload: param,
});

export const setLuckyFarmerGameTrendDataGacha = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_GACHA,
    payload: param,
});

export const setLuckyFarmerGameTrendDataUniqueCount = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_UNIQUE,
    payload: param,
});

export const setLuckyFarmerNftTrendAuctionAll = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setLuckyFarmerNftTrendStoreAll = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setLuckyFarmerNftTrendStoreAllFiat = (param) => ({
    type: LUCKYFARMER.SET_LUCKYFARMER_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});


// COOKIN BURGER
export const setCookinBurgerNftTrendDataLoading = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setCookinBurgerNftTrendDateRange = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getCookinBurgerNftTrendPageData = () => ({
    type: COOKINBURGER.GET_COOKINBURGER_NFT_TREND_PAGE_DATA,
});

export const setCookinBurgerNftTrendPageData = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setCookinBurgerNftTrendDataCountAuction = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setCookinBurgerNftTrendDataAmountAuction = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setCookinBurgerNftTrendDataCountStore = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setCookinBurgerNftTrendDataAmountStore = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setCookinBurgerNftTrendDataCountStoreFiat = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setCookinBurgerNftTrendDataAmountStoreFiat = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_AMOUNT_STORE_FIAT,
    payload: param,
});

export const getCbScholarOwnerScholarStatsData = () => ({
    type: COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB,
});

export const setCookinBurgerNftTrendAuctionAll = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setCookinBurgerNftTrendStoreAll = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setCookinBurgerNftTrendStoreAllFiat = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

export const setCbNumOwnerData = (param) => ({
    type: COOKINBURGER.SET_NUM_OWNER_CB,
    payload: param,
});

export const setCbNumScholarData = (param) => ({
    type: COOKINBURGER.SET_NUM_SCHOLAR_CB,
    payload: param,
});

export const setCbScholarTotalDepData = (param) => ({
    type: COOKINBURGER.SET_SCHOLAR_TOTAL_DEP_COUNT_CB,
    payload: param,
});

export const setCbOverviewOwnerStatsData = (param) => ({
    type: COOKINBURGER.SET_OVERVIEW_OWNER_DATA_CB,
    payload: param,
});

export const setCbOverviewScholarStatsData = (param) => ({
    type: COOKINBURGER.SET_OVERVIEW_SCHOLAR_DATA_CB,
    payload: param,
});

export const setCbDeapCheckAmountData = (param) => ({
    type: COOKINBURGER.SET_DEAP_CHECK_AMOUNT_CB,
    payload: param,
});

export const setCbPerOwnerData = (param) => ({
    type: COOKINBURGER.SET_PER_OWNER_DATA_CB,
    payload: param,
});

export const setCookinBurgerGameTrendDataSales = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_SALES,
    payload: param,
});

export const setCookinBurgerGameTrendDataUnique = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_UNIQUE,
    payload: param,
});

export const setCookinBurgerGameTrendDataAmount = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_AMOUNT,
    payload: param,
});

export const setCookinBurgerGameTrendDataUniqueCount = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_UNIQUE_COUNT,
    payload: param,
});

export const setCookinBurgerGameTrendDataLoading = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_LOADING,
    payload: param,
});

export const setCookinBurgerGameTrendDateRange = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATE_RANGE,
    payload: param,
});

export const getCookinBurgerGameTrendPageData = () => ({
    type: COOKINBURGER.GET_COOKINBURGER_GAME_TREND_PAGE_DATA,
});

export const setCookinBurgerGameTrendPageData = (param) => ({
    type: COOKINBURGER.SET_COOKINBURGER_GAME_TREND_PAGE_DATA,
    payload: param,
});

// DragonRamen
export const setDragonRamenNftTrendDataLoading = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setDragonRamenNftTrendDateRange = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getDragonRamenNftTrendPageData = () => ({
    type: DRAGONRAMEN.GET_DRAGONRAMEN_NFT_TREND_PAGE_DATA,
});

export const setDragonRamenNftTrendPageData = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setDragonRamenNftTrendDataCountAuction = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setDragonRamenNftTrendDataAmountAuction = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setDragonRamenNftTrendDataCountStore = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setDragonRamenNftTrendDataAmountStore = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setDragonRamenNftTrendDataCountStoreFiat = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_DATA_COUNT_STORE_FIAT,
    payload: param,
});

export const setDragonRamenNftTrendDataAmountStoreFiat = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_DATA_AMOUNT_STORE_FIAT,
    payload: param,
});

export const setDragonRamenNftTrendDataAuctionAll = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setDragonRamenNftTrendDataStoreAll = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setDragonRamenNftTrendDataStoreAllFiat = (param) => ({
    type: DRAGONRAMEN.SET_DRAGONRAMEN_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

// Graffiti RACER
export const setGraffitiRacerNftTrendDataLoading = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setGraffitiRacerNftTrendDateRange = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getGraffitiRacerNftTrendPageData = () => ({
    type: GRAFITTIRACER.GET_GRAFITTIRACER_NFT_TREND_PAGE_DATA,
});

export const setGraffitiRacerNftTrendDataCountAuction = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setGraffitiRacerNftTrendDataAmountAuction = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setGraffitiRacerNftTrendDataCountStore = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setGraffitiRacerNftTrendDataAmountStore = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setGraffitiRacerNftTrendDataCountStoreFiat = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setGraffitiRacerNftTrendDataAmountStoreFiat = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_AMOUNT_STORE_FIAT,
    payload: param,
});

export const setGraffitiRacerGameTrendDataLoading = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DATA_LOADING,
    payload: param,
});

export const setGraffitiRacerGameTrendDateRange = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DATE_RANGE,
    payload: param,
});

export const getGraffitiRacerGameTrendPageData = () => ({
    type: GRAFITTIRACER.GET_GRAFITTIRACER_GAME_TREND_PAGE_DATA,
});

export const setGraffitiRacerGameTrendDataDepUse = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DEP_USE,
    payload: param,
});

export const setGraffitiRacerGameTrendDataDepGet = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DEP_GET,
    payload: param,
});

export const setGraffitiRacerGameTrendDataUniqueCount = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT,
    payload: param,
});

export const setGraffitiRacerGameTrendDataUniqueCountFiat = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT_FIAT,
    payload: param,
});

export const setGraffitiRacerNftTrendDataAuctionAll = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setGraffitiRacerNftTrendDataStoreAll = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setGraffitiRacerNftTrendDataStoreAllFiat= (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

export const setGraffitiRacerGameTrendDataDepUseCntDetails = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS,
    payload: param,
});

export const setGraffitiRacerGameTrendDataDepUseAmtDetails = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS,
    payload: param,
});

export const setGraffitiRacerGameTrendDataDepUseCntDetailsFiat = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS_FIAT,
    payload: param,
});

export const setGraffitiRacerGameTrendDataDepUseAmtDetailsFiat = (param) => ({
    type: GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS_FIAT,
    payload: param,
});

// KAMUI VERSE
export const setKamuiVerseNftTrendDataLoading = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const  setKamuiVerseNftTrendDateRange = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getKamuiVerseNftTrendPageData = () => ({
    type: KAMUIVERSE.GET_KAMUIVERSE_NFT_TREND_PAGE_DATA,
});

export const setKamuiVerseNftTrendPageData = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setKamuiVerseNftTrendDataCountAuction = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setKamuiVerseNftTrendDataAmountAuction = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setKamuiVerseNftTrendDataCountStore = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setKamuiVerseNftTrendDataAmountStore = (param) => ({
    type: KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

// JTCB
export const setScholarDataLoading = (param) => ({
    type: JTCB.SET_SCHOLAR_DATA_LOADING,
    payload: param,
});

export const getScholarOwnerScholarStatsData = () => ({
    type: JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA,
});

export const setScholarOwnerScholarStatsData = (param) => ({
    type: JTCB.SET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA,
    payload: param,
});

export const setOverviewOwnerStatsData = (param) => ({
    type: JTCB.SET_OVERVIEW_OWNER_DATA,
    payload: param,
});

export const setOverviewScholarStatsData = (param) => ({
    type: JTCB.SET_OVERVIEW_SCHOLAR_DATA,
    payload: param,
});

export const setPerOwnerData = (param) => ({
    type: JTCB.SET_PER_OWNER_DATA,
    payload: param,
});

export const setLendingCountData = (param) => ({
    type: JTCB.SET_LENDING_COUNT,
    payload: param,
});

export const setDeapCheckAmountData = (param) => ({
    type: JTCB.SET_DEAP_CHECK_AMOUNT,
    payload: param,
});

export const setUniqueOwnerCountByDateData = (param) => ({
    type: JTCB.SET_UNIQUE_OWNER_COUNT_BY_DATE,
    payload: param,
});

export const setUniqueScholarCountByDateData = (param) => ({
    type: JTCB.SET_UNIQUE_SCHOLAR_COUNT_BY_DATE,
    payload: param,
});

export const setOwnerByCountryData = (param) => ({
    type: JTCB.SET_OWNER_BY_COUNTRY,
    payload: param,
});

export const setScholarByCountryData = (param) => ({
    type: JTCB.SET_SCHOLAR_BY_COUNTRY,
    payload: param,
});

export const setScholarTotalAmuletByDateData = (param) => ({
    type: JTCB.SET_TOTAL_AMULET_BY_DATE,
    payload: param,
});

export const setScholarTotalDepByDateData = (param) => ({
    type: JTCB.SET_TOTAL_DEP_BY_DATE,
    payload: param,
});

export const setScholarTotalScholarDepByDateData = (param) => ({
    type: JTCB.SET_TOTAL_SCHOLAR_DEP_BY_DATE,
    payload: param,
});

export const setScholarCountByOwnerData = (param) => ({
    type: JTCB.SET_COUNT_SCHOLAR_BY_OWNER,
    payload: param,
});

export const setScholarAmuletByOwnerData = (param) => ({
    type: JTCB.SET_COUNT_AMULET_BY_OWNER,
    payload: param,
});

export const setScholarDepByOwnerData = (param) => ({
    type: JTCB.SET_COUNT_DEP_BY_OWNER,
    payload: param,
});

export const setScholarDepByScholarData = (param) => ({
    type: JTCB.SET_COUNT_DEP_BY_SCHOLAR,
    payload: param,
});

export const setNumOwnerData = (param) => ({
    type: JTCB.SET_NUM_OWNER,
    payload: param,
});

export const setNumScholarData = (param) => ({
    type: JTCB.SET_NUM_SCHOLAR,
    payload: param,
});

export const setScholarTrendDataLoading = (param) => ({
    type: JTCB.SET_SCHOLAR_TREND_DATA_LOADING,
    payload: param,
});

export const getJtcbScholarTrendData = () => ({
    type: JTCB.GET_JTCB_SCHOLAR_TREND_DATA,
});

export const setJtcbScholarTrendData = (param) => ({
    type: JTCB.SET_JTCB_SCHOLAR_TREND_DATA,
    payload: param,
});

export const setScholarAmuletDataLoading = (param) => ({
    type: JTCB.SET_SCHOLAR_AMULET_DATA_LOADING,
    payload: param,
});

export const getJtcbScholarAmuletData = () => ({
    type: JTCB.GET_JTCB_SCHOLAR_AMULET_DATA,
});

export const setJtcbScholarAmuletData = (param) => ({
    type: JTCB.SET_JTCB_SCHOLAR_AMULET_DATA,
    payload: param,
});

export const setScholarAmuletDataJobLoading = (param) => ({
    type: JTCB.SET_DATA_LOADING_AMULET_JOB,
    payload: param,
});

export const getJtcbScholarAmuletDataByJob = (param) => ({
    type: JTCB.GET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB,
});

export const setJtcbScholarAmuletDataByJob = (param) => ({
    type: JTCB.SET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB,
    payload: param,
});

export const setAmuletSelected = (param) => ({
    type: JTCB.SET_AMULET_SELECTED,
    payload: param,
});

export const getJtcbScholarDataByGuild = () => ({
    type: JTCB.GET_JTCB_SCHOLAR_DATA_BY_GUILD,
});

export const setGuildDataLoading = (param) => ({
    type: JTCB.SET_SCHOLAR_GUILD_DATA_LOADING,
    payload: param,
});

export const setKogiGuildData = (param) => ({
    type: JTCB.SET_KOGI_GUILD_DATA,
    payload: param,
});

export const setKyogiGuildData = (param) => ({
    type: JTCB.SET_KYOGI_GUILD_DATA,
    payload: param,
});

export const setJtcbNftTrendDateRange = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const getJtcbNftTrendPageData = () => ({
    type: JTCB.GET_JTCB_NFT_TREND_PAGE_DATA,
});

export const setJtcbNftTrendPageData = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_PAGE_DATA,
    payload: param,
});

export const setJtcbNftTrendDataLoading = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const setJtcbNftTrendKindSelected = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_KIND_SELECTED,
    payload: param,
});

export const setJtcbNftTrendRareSelected = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_RARE_SELECTED,
    payload: param,
});

export const setJtcbNftTrendDataCountAuction = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_AUCTION,
    payload: param,
});

export const setJtcbNftTrendDataAmountAuction = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_AUCTION,
    payload: param,
});

export const setJtcbNftTrendDataCountStore = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_STORE,
    payload: param,
});

export const setJtcbNftTrendDataAmountStore = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE,
    payload: param,
});

export const setJtcbNftTrendDataCountStoreFiat = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_STORE_FIAT,
    payload: param,
});

export const setJtcbNftTrendDataAmountStoreFiat = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE_FIAT,
    payload: param,
});

export const setJtcbNftTrendAuctionAll = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setJtcbNftTrendStoreAll = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setJtcbNftTrendStoreAllFiat = (param) => ({
    type: JTCB.SET_JTCB_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

// NFTDEP
export const setOverviewTab = (param) => ({
    type: NFTDEP.SET_OVERVIEW_TAB,
    payload: param
});

export const getTodaysSalesData = () => ({
    type: NFTDEP.GET_TODAYS_SALES_DATA,
});

export const setTodaysSalesData = (param) => ({
    type: NFTDEP.SET_TODAYS_SALES_DATA,
    payload: param,
});

export const setDataLoading = (param) => ({
    type: NFTDEP.SET_DATA_LOADING,
    payload: param,
});

export const setFocusTabIndex = (param) => ({
    type: NFTDEP.SET_FOCUS_TAB_INDEX,
    payload: param,
});

export const setDataLoadingForBy = (param) => ({
    type: NFTDEP.SET_DATA_LOADING_FOR_BY,
    payload: param,
});

export const getSalesOverviewData = (param) => ({
    type: NFTDEP.GET_SALES_OVERVIEW_DATA,
});

export const getSalesOverviewDataByGame = (param) => ({
    type: NFTDEP.GET_SALES_OVERVIEW_DATA_BY_GAME,
    payload: param,
});

export const getOverviewDataByCountry = () => ({
    type: NFTDEP.GET_OVERVIEW_DATA_BY_COUNTRY,
});

export const getOverviewGameDataByCountry = (param) => ({
    type: NFTDEP.GET_OVERVIEW_GAME_DATA_BY_COUNTRY,
    payload: param,
});

export const getOverviewDataByPmid = () => ({
    type: NFTDEP.GET_OVERVIEW_DATA_BY_PMID,
});

export const getOverviewDataByGame = () => ({
    type: NFTDEP.GET_OVERVIEW_DATA_BY_GAME,
});

export const getOverviewDataByCampaign = () => ({
    type: NFTDEP.GET_OVERVIEW_DATA_BY_CAMPAIGN,
});

export const setSalesOverviewData = (param) => ({
    type: NFTDEP.SET_SALES_OVERVIEW_DATA,
    payload: param,
});

export const setSalesOverviewDataByGame = (param) => ({
    type: NFTDEP.SET_SALES_OVERVIEW_DATA_BY_GAME,
    payload: param,
});

export const setAllDepSalesData = (param) => ({
    type: NFTDEP.SET_ALL_DEP_SALES_DATA,
    payload: param,
});

export const setAllDepFeesData = (param) => ({
    type: NFTDEP.SET_ALL_DEP_FEES_DATA,
    payload: param,
});

export const setAllTxCountData = (param) => ({
    type: NFTDEP.SET_ALL_TX_COUNT_DATA,
    payload: param,
});

export const setDataByCountry = (param) => ({
    type: NFTDEP.SET_DATA_BY_COUNTRY,
    payload: param,
});

export const setDataByPmid = (param) => ({
    type: NFTDEP.SET_DATA_BY_PMID,
    payload: param,
});

export const setDataByGame = (param) => ({
    type: NFTDEP.SET_DATA_BY_GAME,
    payload: param,
});

export const setDataByRid = (param) => ({
    type: NFTDEP.SET_DATA_BY_RID,
    payload: param,
});

export const setActiveWalletData = (param) => ({
    type: NFTDEP.SET_ACTIVE_WALLET_DATA,
    payload: param,
});

export const setTxUniqueNewData = (param) => ({
    type: NFTDEP.SET_TX_UNIQUE_NEW_DATA,
    payload: param,
});

export const setTxStoreUniqueNewData = (param) => ({
    type: NFTDEP.SET_TX_STORE_UNIQUE_NEW_DATA,
    payload: param,
});

export const setTxBlind = (param) => ({
    type: NFTDEP.SET_DATA_TX_BLIND,
    payload: param,
});

export const setTxSnap = (param) => ({
    type: NFTDEP.SET_DATA_TX_SNAP,
    payload: param,
});

export const setTxAuctionStatsData = (param) => ({
    type: NFTDEP.SET_TX_AUCTION_STATS_DATA,
    payload: param,
});

export const setTxStoreStatsData = (param) => ({
    type: NFTDEP.SET_TX_STORE_STATS_DATA,
    payload: param,
});

export const setTxFiatStatsData = (param) => ({
    type: NFTDEP.SET_TX_FIAT_STATS_DATA,
    payload: param,
});

export const setTxFirstTimePurchaseStatsData = (param) => ({
    type: NFTDEP.SET_TX_FIRST_TIME_PURCHASE_STATS_DATA,
    payload: param,
});

export const setStakingOvDataCount = (param) => ({
    type: NFTDEP.SET_DATA_STAKING_OV_COUNT,
    payload: param,
});

export const setStakingOvDataDep = (param) => ({
    type: NFTDEP.SET_DATA_STAKING_OV_DEP,
    payload: param,
});

export const setTxHistorySearchTerm = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_SEARCH_TERM,
    payload: param,
});

export const setTxHistorySearchLoading = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_SEARCH_LOADING,
    payload: param,
});

export const getNftAssetSearch = () => ({
    type: NFTDEP.GET_NFT_ASSET_SEARCH,
});

export const setTxHistorySearchOption = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_SEARCH_OPTION,
    payload: param,
});

export const getNftAssetHistoryData = (param) => ({
    type: NFTDEP.GET_NFT_TX_HISTORY_DATA,
    payload: param,
});

export const getNftAssetHistoryDataByTerm = () => ({
    type: NFTDEP.GET_NFT_TX_HISTORY_DATA_BY_TERM,
});

export const setTxHistoryDataLoading = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_DATA_LOADING,
    payload: param,
});

export const setTxHistoryData = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_DATA,
    payload: param,
});

export const setTxHistoryStatData = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_STAT_DATA,
    payload: param,
});

export const getNftLowTxAssetHistoryData = (param) => ({
    type: NFTDEP.GET_NFT_LOW_TX_HISTORY_DATA,
    payload: param,
});

// Verse Nft Holders Start
export const getNftHoldersData = (param) => ({
    type: NFTDEP.GET_NFT_HOLDERS_DATA,
    payload: param,
});

export const setNftHoldersData = (param) => ({
    type: NFTDEP.SET_NFT_HOLDERS_DATA,
    payload: param,
});

export const setNftHoldersDataLoading = (param) => ({
    type: NFTDEP.SET_NFT_HOLDERS_DATA_LOADING,
    payload: param,
});

export const setNftHoldersTotalCount = (param) => ({
    type: NFTDEP.SET_NFT_HOLDERS_TOTAL_COUNT,
    payload: param,
});

export const setNftHoldersPageNumber = (param) => ({
    type: NFTDEP.SET_NFT_HOLDERS_PAGE_NUMBER,
    payload: param,
});

export const setNftHoldersStatsData = (param) => ({
    type: NFTDEP.SET_NFT_HOLDERS_STATS_DATA,
    payload: param,
});
// Verse Nft Holders End

export const setLowTxHistoryData = (param) => ({
    type: NFTDEP.SET_LOW_TX_HISTORY_DATA,
    payload: param,
});

export const setLowTxHistoryStatData = (param) => ({
    type: NFTDEP.SET_LOW_TX_HISTORY_STAT_DATA,
    payload: param,
});

export const setTxHistoryDataFilter = (param) => ({
    type: NFTDEP.SET_TX_HISTORY_DATA_FILTER,
    payload: param,
});

export const setPageNumber = (param) => ({
    type: NFTDEP.SET_PAGE_NUMBER,
    payload: param,
});

export const setLowTxDepRange = (param) => ({
    type: NFTDEP.SET_LOW_TX_DEP_RANGE,
    payload: param,
});

export const getLowTxCsvData = () => ({
    type: NFTDEP.GET_LOW_TX_CSV_DATA,
});

export const setLowTxCsvData = (param) => ({
    type: NFTDEP.SET_LOW_TX_CSV_DATA,
    payload: param,
});

export const setCsvDownloader = (param) => ({
    type: NFTDEP.SET_LOW_CSV_DOWNLOADER,
    payload: param,
});

export const getNftTxCsvData = () => ({
    type: NFTDEP.GET_NFT_TX_CSV_DATA,
});

export const setNftTxCsvData = (param) => ({
    type: NFTDEP.SET_NFT_TX_CSV_DATA,
    payload: param,
});

export const setNftTxCsvDownloader = (param) => ({
    type: NFTDEP.SET_NFT_CSV_DOWNLOADER,
    payload: param,
});

// NFTDEP: Staking
export const setStakingDataLoading = (param) => ({
    type: NFTDEP.SET_STAKING_DATA_LOADING,
    payload: param,
});

export const getStakingTotalData = () => ({
    type: NFTDEP.GET_STAKING_TOTAL_DATA,
});

export const setStakingTotalData = (param) => ({
    type: NFTDEP.SET_STAKING_TOTAL_DATA,
    payload: param,
});

export const getStakingStatData = () => ({
    type: NFTDEP.GET_STAKING_STAT_DATA,
});

export const setStakingStatData = (param) => ({
    type: NFTDEP.SET_STAKING_STAT_DATA,
    payload: param,
});

// Wallet
export const getWalletBalanceData = (param) => ({
    type: NFTDEP.GET_WALLET_BALANCE_DATA,
    payload: param
});

export const setWalletBalanceData = (param) => ({
    type: NFTDEP.SET_WALLET_BALANCE_DATA,
    payload: param
});

// NFTDEP: Trend
export const setNftDepTrendDateRange = (param) => ({
    type: NFTDEP.SET_TREND_DATE_RANGE,
    payload: param
});

export const setNftDepTrendOption = (param) => ({
    type: NFTDEP.SET_TREND_OPTION,
    payload: param
});

export const setNftDepTrendOptionSelected = (param) => ({
    type: NFTDEP.SET_TREND_OPTION_SELECTED,
    payload: param
});

export const getNftDepTrendPageData = () => ({
    type: NFTDEP.GET_TREND_PAGE_DATA,
});

export const setNftDepTrendPageDataCount = (param) => ({
    type: NFTDEP.SET_TREND_PAGE_DATA_COUNT,
    payload: param
});

export const setNftDepTrendPageDataAmount = (param) => ({
    type: NFTDEP.SET_TREND_PAGE_DATA_AMOUNT,
    payload: param
});

export const setNftDepTrendPageDataSummary = (param) => ({
    type: NFTDEP.SET_TREND_PAGE_DATA_SUMMARY,
    payload: param
});

export const setNftDepTrendLoading = (param) => ({
    type: NFTDEP.SET_TREND_DATA_LOADING,
    payload: param
});

export const setNftDepTrendCountryLoading = (param) => ({
    type: NFTDEP.SET_TREND_DATA_COUNTRY_LOADING,
    payload: param
});

export const getNftDepTrendDataCountry = (param) => ({
    type: NFTDEP.GET_TREND_DATA_COUNTRY,
});

export const setNftDepTrendDataCountryOption = (param) => ({
    type: NFTDEP.SET_TREND_DATA_COUNTRY_OPTION,
    payload: param
});

export const getNftDepTrendDataGames = (param) => ({
    type: NFTDEP.GET_TREND_DATA_GAMES
});

export const setNftDepTrendDataGameOption = (param) => ({
    type: NFTDEP.SET_TREND_DATA_GAME_OPTION,
    payload: param
});

export const setNftDepTrendDataGameSelected = (param) => ({
    type: NFTDEP.SET_TREND_DATA_GAME_SELECTED,
    payload: param
});

export const setNftDepTrendDataCountrySelected = (param) => ({
    type: NFTDEP.SET_TREND_DATA_COUNTRY_SELECTED,
    payload: param
});

export const getTrendTxKinds = () => ({
    type: NFTDEP.GET_TREND_TX_KINDS,
});

// NFTDEP: NFT OWN TREND
export const setNftOwnTrendDateRange = (param) => ({
    type: NFTDEP.SET_NFT_OWN_TREND_DATE_RANGE,
    payload: param
});

export const setNftOwnTrendOption = (param) => ({
    type: NFTDEP.SET_NFT_OWN_TREND_OPTION,
    payload: param
});

export const setNftOwnTrendOptionSelected = (param) => ({
    type: NFTDEP.SET_NFT_OWN_TREND_OPTION_SELECTED,
    payload: param
});

export const setNftOwnTrendDataLoading = (param) => ({
    type: NFTDEP.SET_NFT_OWN_TREND_DATA_LOADING,
    payload: param
});

export const getNftOwnTrendData = () => ({
    type: NFTDEP.GET_NFT_OWN_TREND_DATA,
});

export const setNftOwnTrendData = (param) => ({
    type: NFTDEP.SET_NFT_OWN_TREND_DATA,
    payload: param
});

// Puzzle
export const getAcquisitionData = () => ({
    type: PUZZLE.GET_ACQUISITION_DATA,
});

export const setAcquisitionDataLoading = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_LOADING,
    payload: param
});

export const setAcquisitionDataReg = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG,
    payload: param
});

export const setAcquisitionDataRegGender = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG_GENDER,
    payload: param
});

export const setAcquisitionDataRegAgeGroup = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG_AGE_GROUP,
    payload: param
});

export const setAcquisitionDataRegAdVsOrganic = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG_AD_VS_ORG,
    payload: param
});

export const setAcquisitionDataRegNewVsExist = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG_NEW_VS_EXIST,
    payload: param
});

export const setAcquisitionDataRegTrend = (param) => ({
    type: PUZZLE.SET_ACQUISITION_DATA_REG_TREND,
    payload: param
});

// Puzzle Geo
export const getAcquisitionGeoDataReg = () => ({
    type: PUZZLE_GEO.GET_ACQUISITION_GEO_DATA_REG,
});

export const setAcquisitionGeoDataReg = (param) => ({
    type: PUZZLE_GEO.SET_ACQUISITION_GEO_DATA_REG,
    payload: param
});

export const setAcquisitionCountryByAd = (param) => ({
    type: PUZZLE_GEO.SET_ACQUISITION_COUNTRY_BY_AD,
    payload: param
});

export const setAcquisitionCountryByOrg = (param) => ({
    type: PUZZLE_GEO.SET_ACQUISITION_COUNTRY_BY_ORGANIC,
    payload: param
});

// Puzzle User Events
export const getActiveUsersData = () => ({
    type: PUZZLE.GET_ACTIVE_USERS_DATA,
});

export const setActiveUsersData = (param) => ({
    type: PUZZLE.SET_ACTIVE_USERS_DATA,
    payload: param
});

export const setActiveUsersTrendData = (param) => ({
    type: PUZZLE.SET_ACTIVE_USERS_TREND_DATA,
    payload: param
});

export const getUserEventData = () => ({
    type: PUZZLE.GET_USER_EVENT_DATA,
});

export const setUserEventData = (param) => ({
    type: PUZZLE.SET_USER_EVENT_DATA,
    payload: param
});

export const setUserEventTrendData = (param) => ({
    type: PUZZLE.SET_USER_EVENT_TREND_DATA,
    payload: param
});

export const getUserStageClearData = () => ({
    type: PUZZLE.GET_USER_STAGE_CLEAR_DATA,
});

export const setUserStageClearData = (param) => ({
    type: PUZZLE.SET_USER_STAGE_CLEAR_DATA,
    payload: param
});

export const getUserStageFailedData = () => ({
    type: PUZZLE.GET_USER_STAGE_FAILED_DATA,
});

export const setUserStageFailedData = (param) => ({
    type: PUZZLE.SET_USER_STAGE_FAILED_DATA,
    payload: param
});

// Cohort
export const setCohortFocused = (param) => ({
    type: DATA.SET_COHORT_FOCUSED,
    payload: param
});

export const setCohortSize = (param) => ({
    type: DATA.SET_COHORT_SIZE,
    payload: param
});

export const setCohortSizeSelected = (param) => ({
    type: DATA.SET_COHORT_SIZE_SELECTED,
    payload: param
});

export const setCohortCountryOptions = (param) => ({
    type: DATA.SET_COHORT_COUNTRY_OPTIONS,
    payload: param
});

export const setCohortCountrySelected = (param) => ({
    type: DATA.SET_COHORT_COUNTRY_SELECTED,
    payload: param
});

export const setCohortRidOptions = (param) => ({
    type: DATA.SET_COHORT_RID_OPTIONS,
    payload: param
});

export const setCohortRidSelected = (param) => ({
    type: DATA.SET_COHORT_RID_SELECTED,
    payload: param
});

export const setCohortRange = (param) => ({
    type: DATA.SET_COHORT_RANGE,
    payload: param
});

export const setCohortRangeSelected = (param) => ({
    type: DATA.SET_COHORT_RANGE_SELECTED,
    payload: param
});

export const getCohortData = () => ({
    type: DATA.GET_COHORT_DATA
});

export const setCohortData = (param) => ({
    type: DATA.SET_COHORT_DATA,
    payload: param
});

export const setCohortNftData = (param) => ({
    type: DATA.SET_COHORT_NFT_DATA,
    payload: param
});

export const setCohortDateRange = (param) => ({
    type: DATA.SET_COHORT_DATE_RANGE,
    payload: param
});

// Game NFT Trend
export const getGameNftTrendPageData = (param) => ({
    type: DATA.GET_GAME_NFT_TREND_PAGE_DATA,
    payload: param
});

export const setGameNftTrendPageData = () => ({
    type: DATA.SET_GAME_NFT_TREND_PAGE_DATA,
});

export const setGameNftTrendDataLoading = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_DATA_LOADING,
    payload: param,
});

export const setGameNftTrendDateRange = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_DATE_RANGE,
    payload: param,
});

export const setGameNftTrendStoreAllFiat = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_STORE_ALL_FIAT,
    payload: param,
});

export const setGameNftTrendDataCountAuction = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_COUNT_AUCTION,
    payload: param,
});

export const setGameNftTrendDataAmountAuction = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_AMOUNT_AUCTION,
    payload: param,
});

export const setGameNftTrendDataCountStore = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_COUNT_STORE,
    payload: param,
});

export const setGameNftTrendDataAmountStore = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_AMOUNT_STORE,
    payload: param,
});

export const setGameNftTrendDataCountStoreFiat = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_COUNT_STORE_FIAT,
    payload: param,
});

export const setGameNftTrendAuctionAll = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_AUCTION_ALL,
    payload: param,
});

export const setGameNftTrendStoreAll = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_STORE_ALL,
    payload: param,
});

export const setGameNftTrendDataAmountStoreFiat = (param) => ({
    type: DATA.SET_GAME_NFT_TREND_DATA_AMOUNT_STORE_FIAT,
    payload: param,
});

// KPI
export const getAverageRevenuePageData = () => ({
    type: KPI.GET_AVG_REVENUE_PAGE_DATA
});

export const getAverageRevenueFilterOption = () => ({
    type: KPI.GET_AVG_REVENUE_FILTER_OPTION
});

export const setAverageRevenueGameOption = (param) => ({
    type: KPI.SET_AVG_REVENUE_GAME_OPTION,
    payload: param
});

export const setAverageRevenueCountryOption = (param) => ({
    type: KPI.SET_AVG_REVENUE_COUNTRY_OPTION,
    payload: param
});

export const setAverageRevenueRidOption = (param) => ({
    type: KPI.SET_AVG_REVENUE_RID_OPTION,
    payload: param
});

export const setAverageRevenueGameSelected = (param) => ({
    type: KPI.SET_AVG_REVENUE_GAME_SELECTED,
    payload: param
});

export const setAverageRevenueCountrySelected = (param) => ({
    type: KPI.SET_AVG_REVENUE_COUNTRY_SELECTED,
    payload: param
});

export const setAverageRevenueRidSelected = (param) => ({
    type: KPI.SET_AVG_REVENUE_RID_SELECTED,
    payload: param
});

export const setAverageRevenueGame = (param) => ({
    type: KPI.SET_AVG_REVENUE_GAME,
    payload: param
});

export const getAverageRevenueCountries = () => ({
    type: KPI.GET_AVG_REVENUE_COUNTRIES
});

export const setAverageRevenueCountries = (param) => ({
    type: KPI.SET_AVG_REVENUE_COUNTRIES,
    payload: param
});

export const getAverageRevenueRid = () => ({
    type: KPI.GET_AVG_REVENUE_RID
});

export const setAverageRevenueRid = (param) => ({
    type: KPI.SET_AVG_REVENUE_RID,
    payload: param
});

export const setAverageRevenueLoading = (param) => ({
    type: KPI.SET_AVG_REVENUE_LOADING,
    payload: param
});

export const setAverageRevenueDateRange = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATE_RANGE,
    payload: param
});

export const setAverageRevenueData = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA,
    payload: param
});

export const setAllPmForAvgRevenueSelect = (param) => ({
    type: KPI.SET_ALL_PM_FOR_AVG_REVENUE_SELECT,
    payload: param
});

export const setAverageRevenueDataByGame = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_GAME,
    payload: param
});

export const setAverageRevenueDataByCountry = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_COUNTRY,
    payload: param
});

export const setAverageRevenueDataByRid = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_RID,
    payload: param
});

export const setAverageRevenueDataByGamePaid = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_GAME_PAID,
    payload: param
});

export const setAverageRevenueDataByCountryPaid = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_COUNTRY_PAID,
    payload: param
});

export const setAverageRevenueDataByRidPaid = (param) => ({
    type: KPI.SET_AVG_REVENUE_DATA_BY_RID_PAID,
    payload: param
});

export const setPaidPmidCsvData = (param) => ({
    type: KPI.SET_PAID_PMID_CSV_DATA,
    payload: param
});

export const setAllPmidCsvData = (param) => ({
    type: KPI.SET_ALL_PMID_CSV_DATA,
    payload: param
});

export const setKpiSalesDateRange = (param) => ({
    type: KPI.SET_KPI_SALES_DATE_RANGE,
    payload: param
});

export const getKpiSalesData = (param) => ({
    type: KPI.GET_KPI_SALES_DATA,
    payload: param
});

export const setKpiSalesData = (param) => ({
    type: KPI.SET_KPI_SALES_DATA,
    payload: param
});

export const setKpiSalesDataSizeSelected = (param) => ({
    type: KPI.SET_KPI_SALES_DATA_SIZE_SELECTED,
    payload: param
});

// Active Users
export const setActiveUsersUniqueTotalData = (param) => ({
    type: DATA.SET_ACTIVE_USERS_UNIQUE_TOTAL_DATA,
    payload: param
});

export const setActiveUsersUniqueTotalPaidData = (param) => ({
    type: DATA.SET_ACTIVE_USERS_UNIQUE_PAID_DATA,
    payload: param
});

export const getActiveUsersPageData = () => ({
    type: DATA.GET_ACTIVE_USERS_PAGE_DATA,
});

export const setActiveUsersPageData = (param) => ({
    type: DATA.SET_ACTIVE_USERS_PAGE_DATA,
    payload: param
});

export const setActiveUsersPageDataByGame = (param) => ({
    type: DATA.SET_ACTIVE_USERS_PAGE_DATA_BY_GAME,
    payload: param
});

export const setActiveUsersSizeOption = (param) => ({
    type: DATA.SET_ACTIVE_USERS_SIZE_OPTION,
    payload: param
});

export const setActiveUsersSizeSelected = (param) => ({
    type: DATA.SET_ACTIVE_USERS_SIZE_SELECTED,
    payload: param
});

export const setActiveUsersGameOption = (param) => ({
    type: DATA.SET_ACTIVE_USERS_GAME_OPTION,
    payload: param
});

export const setActiveUsersGameSelected = (param) => ({
    type: DATA.SET_ACTIVE_USERS_GAME_SELECTED,
    payload: param
});

export const setActiveUsersDateRange = (param) => ({
    type: DATA.SET_ACTIVE_USERS_DATE_RANGE,
    payload: param
});

export const setActiveUsersDateDiff = (param) => ({
    type: DATA.SET_ACTIVE_USERS_DATE_DIFF,
    payload: param
});

export const setActiveUsersCountryOption = (param) => ({
    type: DATA.SET_ACTIVE_USERS_COUNTRY_OPTION,
    payload: param
});

export const setActiveUsersCountrySelected = (param) => ({
    type: DATA.SET_ACTIVE_USERS_COUNTRY_SELECTED,
    payload: param
});

export const setActiveUsersRidOption = (param) => ({
    type: DATA.SET_ACTIVE_USERS_RID_OPTION,
    payload: param
});

export const setActiveUsersRidSelected = (param) => ({
    type: DATA.SET_ACTIVE_USERS_RID_SELECTED,
    payload: param
});

export const resetActiveUsersGameSelected = () => ({
    type: DATA.RESET_ACTIVE_USERS_GAME_SELECTED,
});

export const getActiveUsersCountries = () => ({
    type: DATA.GET_ACTIVE_USERS_COUNTRIES,
});

export const setActiveUsersCountryLoading = (param) => ({
    type: DATA.SET_ACTIVE_USERS_COUNTRY_LOADING,
    payload: param
});

export const setActiveUsersRidLoading = (param) => ({
    type: DATA.SET_ACTIVE_USERS_RID_LOADING,
    payload: param
});

export const setAllPmMenuSelect = (param) => ({
    type: DATA.SET_ALL_PM_MENU_SELECT,
    payload: param
});

export const getCampaingsByGame = (param) => ({
    type: DATA.GET_CAMPAIGN_BY_GAME,
    payload: param
});

export const setGameOptionLoader = (param) => ({
    type: DATA.SET_GAME_OPTION_LOADER,
    payload: param,
});

export const setCampaignOption = (param) => ({
    type: DATA.SET_CAMPAIGN_OPTION,
    payload: param,getActiveUsersCountriesByGame
});

export const setGameSelected = (param) => ({
    type: DATA.SET_GAME_SELECTED,
    payload: param,
});

export const setCampaignSelected = (param) => ({
    type: DATA.SET_CAMPAIGN_SELECTED,
    payload: param,
});

export const getActiveUsersPageDataByGame = (param) => ({
    type: DATA.GET_ACTIVE_USERS_PAGE_DATA_BY_GAME,
    payload: param
});

export const getActiveUsersCountriesByGame = (param) => ({
    type: DATA.GET_ACTIVE_USERS_COUNTRIES_BY_GAME,
    payload: param
});

export const getActiveUsersRidByGame = (param) => ({
    type: DATA.GET_ACTIVE_USERS_RID_BY_GAME,
    payload: param
});

// DATA IN GAME SALES
export const setInGameSalesLoading = (param) => ({
    type: DATA.SET_IN_GAME_SALES_LOADING,
    payload: param
});

export const getInGameItemSalesOverviewData = (param) => ({
    type: DATA.GET_IN_GAME_SALES_DATA_OVERVIEW,
    payload: param
});

export const setInGameItemSalesOverviewData = (param) => ({
    type: DATA.SET_IN_GAME_SALES_DATA_OVERVIEW,
    payload: param,
});

export const getInGameItemSalesByCountry = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_COUNTRY,
    payload: param,
});

export const setInGameItemSalesByCountry = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_BY_COUNTRY,
    payload: param,
});

export const getInGameItemSalesByGame = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_GAME,
    payload: param,
});

export const setInGameItemSalesByGame = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_BY_GAME,
    payload: param,
});

export const getInGameItemSalesByItem = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_ITEM,
    payload: param,
});

export const setInGameItemSalesByItem = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_BY_ITEM,
    payload: param,
});

export const getInGameItemSalesByPmid = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_PMID,
    payload: param,
});

export const setInGameItemSalesByPmid = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_BY_PMID,
    payload: param,
});

export const getInGameItemSalesByRid = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_RID,
    payload: param,
});

export const getInGameItemSalesByOccurrence = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_BY_OCCURRENCE,
    payload: param,
});

export const setInGameItemSalesByOccurrence = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_BY_OCCURRENCE,
    payload: param,
});

export const getInGameItemHistoryCsvData = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_CSV,
    payload: param,
});

export const setInGameItemHistoryCsvData = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_CSV,
    payload: param,
});

export const getNftSalesByOccurrence = (param) => ({
    type: DATA.GET_NFT_SALES_DATA_BY_OCCURRENCE,
    payload: param,
});

export const setNftSalesByOccurrence = (param) => ({
    type: DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE,
    payload: param,
});

export const setNftSalesByOccurrenceType = (param) => ({
    type: DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE_TYPE,
    payload: param,
});

export const setNftSalesByOccurrenceGame = (param) => ({
    type: DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE_GAME,
    payload: param,
});


// Details
export const getInGameItemHistoryData = (param) => ({
    type: DATA.GET_INGAME_ITEM_TX_HISTORY_DATA,
    payload: param,
});

export const getInGameItemHistoryDataByTerm = (param) => ({
    type: DATA.GET_INGAME_ITEM_TX_HISTORY_DATA_BY_TERM,
    payload: param,
});

export const setInGameItemTxHistoryDataLoading = (param) => ({
    type: DATA.SET_INGAME_ITEM_TX_HISTORY_DATA_LOADING,
    payload: param,
});

export const setInGameItemTxHistoryData = (param) => ({
    type: DATA.SET_INGAME_ITEM_TX_HISTORY_DATA,
    payload: param,
});

export const setInGameItemTxHistoryStatData = (param) => ({
    type: DATA.SET_INGAME_ITEM_TX_HISTORY_STAT_DATA,
    payload: param,
});

export const setInGamePageNumber = (param) => ({
    type: DATA.SET_INGAME_PAGE_NUMBER,
    payload: param,
});

export const setInGameTxHistorySearchTerm = (param) => ({
    type: DATA.SET_INGAME_ITEM_TX_HISTORY_SEARCH_TERM,
    payload: param,
});

export const setInGameItemItemOptionDataLoading = (param) => ({
    type: DATA.SET_INGAME_ITEM_OPTION_DATA_LOADING,
    payload: param,
});

export const getInGameItemOptionData = (param) => ({
    type: DATA.GET_INGAME_ITEM_OPTION_DATA,
    payload: param
});

export const setInGameItemOptionData = (param) => ({
    type: DATA.SET_INGAME_ITEM_OPTION_DATA,
    payload: param
});

export const setInGameItemOptionSelected = (param) => ({
    type: DATA.SET_INGAME_ITEM_OPTION_SELECTED,
    payload: param
});

export const setInGamePyamentOptionSelected = (param) => ({
    type: DATA.SET_INGAME_PAYMENT_OPTION_SELECTED,
    payload: param
});

export const getInGamePmidOptionData = (param) => ({
    type: DATA.GET_INGAME_PMID_OPTION_DATA,
    payload: param
});

export const setInGamePmidOptionDataLoading = (param) => ({
    type: DATA.SET_INGAME_PMID_OPTION_DATA_LOADING,
    payload: param,
});

export const setInGamePmidOptionData = (param) => ({
    type: DATA.SET_INGAME_PMID_OPTION_DATA,
    payload: param
});

export const setInGamePmidOptionSelected = (param) => ({
    type: DATA.SET_INGAME_PMID_OPTION_SELECTED,
    payload: param
});

// TREND
export const setInGameSalesTrendLoading = (param) => ({
    type: DATA.SET_INGAME_SALES_DATA_TREND_LOADING,
    payload: param
});

export const getInGameItemSalesTrendData = (param) => ({
    type: DATA.GET_INGAME_SALES_DATA_TREND,
    payload: param
});

export const setInGameItemSalesTrendDataCount = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT,
    payload: param
});

export const setInGameItemSalesTrendDataAmount = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT,
    payload: param
});

export const setInGameItemSalesTrendDataCountUnique = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT_UNIQUE,
    payload: param
});

export const setInGameItemDepSalesTrendDataCount = (param) => ({
    type: DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT,
    payload: param
});

export const setInGameItemDepSalesTrendDataAmount = (param) => ({
    type: DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_AMOUNT,
    payload: param
});

export const setInGameItemDepSalesTrendDataCountUnique = (param) => ({
    type: DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT_UNIQUE,
    payload: param
});

export const setInGameItemSalesTrendDataCountByItem = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT_BY_ITEM,
    payload: param
});

export const setInGameItemSalesTrendDataAmountByItem = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT_BY_ITEM,
    payload: param
});


export const getInGameItemSalesTrendItemsData = (param) => ({
    type: DATA.GET_INGAME_SALES_TREND_DATA_ITEMS,
    payload: param
});

export const setInGameItemSalesTrendItemsData = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_DATA_ITEMS,
    payload: param
});

export const setInGameItemSalesTrendType = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_TYPE,
    payload: param
});

export const setInGameItemSalesTrendItemSelected = (param) => ({
    type: DATA.SET_INGAME_SALES_TREND_ITEM_SELECTED,
    payload: param
});

// Map
export const changeMapTheme = (mapTheme) => ({
    type: MAP.CHANGE_THEME,
    payload: mapTheme
});

export const setMapFilterStatus = (param) => ({
    type: MAP.SET_FILTER_STATUS,
    payload: param
});

export const setShowPoiData = (param, numLocation) => ({
    type: MAP.SET_SHOW_POI_DATA,
    payload: param,
    numLocation
});

export const showPoiDataOnMap = (param) => ({
    type: MAP.SHOW_POI_DATA_ON_MAP,
    payload: param
});

export const setPoiSearchData = (param, numLocation) => ({
    type: MAP.SET_POI_SEARCH_DATA,
    payload: param,
    numLocation
});

export const setZoomLevel = (param) => ({
    type: MAP.SET_ZOOM_LEVEL,
    payload: param
});

export const setPoiCoord = (param) => ({
    type: MAP.SET_POI_COORD,
    payload: param
});

export const setPolygon = (param) => ({
    type: MAP.SET_POLYGON,
    payload: param,
});

export const resetPoiSearchData = (param) => ({
    type: MAP.RESET_POI_SEARCH_DATA,
    locIndex: param
});

export const clearPoiSearchData = (param) => ({
    type: MAP.CLEAR_POI_SEARCH_DATA,
    payload: param
});

export const setMapFitbounds = (param) => ({
    type: MAP.SET_MAP_FITBOUNDS,
    payload: param,
});

export const updateSelectedPoiData = (payload, filterType, index) => ({
    type: MAP.UPDATE_SELECTED_POI,
    payload,
    filterType,
    index
});

// DATA LOAD HANDLE
export const setDataLoad = (param) => ({
    type: DATA_LOAD.SET_DATA_LOADED,
    payload: param,
});

// PicTree
export const setPictreeView = (param) => ({
    type: PICTREE.SET_PICTREE_VIEW,
    payload: param
});

export const getHomePictreeUser = (param) => ({
    type: PICTREE.GET_HOME_USER,
    payload: param
});

export const setHomePictreeUser = (param) => ({
    type: PICTREE.SET_HOME_USER,
    payload: param
});

export const getHomePictreeCheckIn = (param) => ({
    type: PICTREE.GET_HOME_CHECKIN,
    payload: param
});

export const setHomePictreeCheckIn = (param) => ({
    type: PICTREE.SET_HOME_CHECKIN,
    payload: param
});

export const getHomePictreePhotoShoot = (param) => ({
    type: PICTREE.GET_HOME_PHOTOSHOOT,
    payload: param
});

export const setHomePictreePhotoShoot = (param) => ({
    type: PICTREE.SET_HOME_PHOTOSHOOT,
    payload: param
});

export const getPhotoShootDetailsData = (param) => ({
    type: PICTREE.GET_PHOTOSHOOT_DETAILS,
    payload: param
});

export const setPhotoShootDetailsData = (param) => ({
    type: PICTREE.SET_PHOTOSHOOT_DETAILS,
    payload: param
});

export const getPhotoShootMapData = (param) => ({
    type: PICTREE.GET_PHOTOSHOOT_MAP_DATA,
    payload: param
});

export const setPhotoShootMapData = (param) => ({
    type: PICTREE.SET_PHOTOSHOOT_MAP_DATA,
    payload: param
});

// PicTree - Registration
export const getPictreeDataRegistTrend = (param) => ({
    type: PICTREE.GET_REGIST_TREND,
    payload: param
});

export const setPictreeDataRegistTrend = (param) => ({
    type: PICTREE.SET_REGIST_TREND,
    payload: param
});

export const getPictreeDataRegistTable = (param) => ({
    type: PICTREE.GET_REGIST_TABLE,
    payload: param
});

export const setPictreeDataRegistTable = (param) => ({
    type: PICTREE.SET_REGIST_TABLE,
    payload: param
});

// PicTree - Sales
export const getSalesOverviewDataPicTree = (param) => ({
    type: PICTREE.GET_SALES_OVERVIEW,
    payload: param
});

export const setSalesOverviewDataPicTree = (param) => ({
    type: PICTREE.SET_SALES_OVERVIEW,
    payload: param
});

export const getSalesTrendDataPicTree = (param) => ({
    type: PICTREE.GET_SALES_TREND,
    payload: param
});

export const setSalesTrendDataPicTree = (param) => ({
    type: PICTREE.SET_SALES_TREND,
    payload: param
});

export const getSalesTableDataPicTree = (param) => ({
    type: PICTREE.GET_SALES_TABLE,
    payload: param
});

export const setSalesTableDataPicTree = (param) => ({
    type: PICTREE.SET_SALES_TABLE,
    payload: param
});

// PicTree - Sales Point Exchange
export const getPointOverviewDataPicTree = (param) => ({
    type: PICTREE.GET_POINT_OVERVIEW,
    payload: param
});

export const setPointOverviewDataPicTree = (param) => ({
    type: PICTREE.SET_POINT_OVERVIEW,
    payload: param
});

export const getPointTrendDataPicTree = (param) => ({
    type: PICTREE.GET_POINT_TREND,
    payload: param
});

export const setPointTrendDataPicTree = (param) => ({
    type: PICTREE.SET_POINT_TREND,
    payload: param
});

export const getPointTableDataPicTree = (param) => ({
    type: PICTREE.GET_POINT_TABLE,
    payload: param
});

export const setPointTableDataPicTree = (param) => ({
    type: PICTREE.SET_POINT_TABLE,
    payload: param
});

// PicTree - CheckIn
export const getPictreeDataCheckInTrend = (param) => ({
    type: PICTREE.GET_CHECKIN_TREND,
    payload: param
});

export const setPictreeDataCheckInTrend = (param) => ({
    type: PICTREE.SET_CHECKIN_TREND,
    payload: param
});

export const getPictreeDataCheckInMap = (param) => ({
    type: PICTREE.GET_CHECKIN_MAP,
    payload: param
});

export const setPictreeDataCheckInMap = (param) => ({
    type: PICTREE.SET_CHECKIN_MAP,
    payload: param
});

export const getPictreeDataCheckInTable = (param) => ({
    type: PICTREE.GET_CHECKIN_TABLE,
    payload: param
});

export const setPictreeDataCheckInTable = (param) => ({
    type: PICTREE.SET_CHECKIN_TABLE,
    payload: param
});

// PicTree - PhotoShoot
export const getPictreeDataPhotoShootTrend = (param) => ({
    type: PICTREE.GET_PHOTOSHOOT_TREND,
    payload: param
});

export const setPictreeDataPhotoShootTrend = (param) => ({
    type: PICTREE.SET_PHOTOSHOOT_TREND,
    payload: param
});

// PicTree - PhotoShoot Denchu Kaisu
export const getPictreeDataPhotoDenchuKaisuTrend = (param) => ({
    type: PICTREE.GET_PHOTOSHOOT_DENCHU_KAISU_TREND,
    payload: param
});

export const setPictreeDataPhotoDenchuKaisuTrend = (param) => ({
    type: PICTREE.SET_PHOTOSHOOT_DENCHU_KAISU_TREND,
    payload: param
});

// PicTree - Users
export const getPicTreeUserListData = (param) => ({
    type: PICTREE.GET_USER_LIST,
    payload: param
});

export const setPicTreeUserListData = (param) => ({
    type: PICTREE.SET_USER_LIST,
    payload: param
});

export const setPicTreeUserPageNumber = (param) => ({
    type: PICTREE.SET_PICTREE_USER_PAGE_NUMBER,
    payload: param,
});

export const getPicTreeUserData = (param) => ({
    type: PICTREE.GET_USER_DATA,
    payload: param
});

// PicTree - Util
export const getPictreeChartSize = (param) => ({
    type: PICTREE.GET_CHART_SIZE,
    payload: param
});

export const setPictreeChartSize = (param) => ({
    type: PICTREE.SET_CHART_SIZE,
    payload: param
});

export const setPictreeMapType = (param) => ({
    type: PICTREE.SET_MAP_TYPE,
    payload: param
});

// PicTree - Mission
export const getMissionData = () => ({
    type: PICTREE.GET_MISSION_DATA,
});

export const setMissionData = (param) => ({
    type: PICTREE.SET_MISSION_DATA,
    payload: param
});

export const setMissionSummaryData = (param) => ({
    type: PICTREE.SET_MISSION_SUMMARY_DATA,
    payload: param
});

export const getMissionRankingData = (param) => ({
    type: PICTREE.GET_MISSION_RANKING_DATA,
    payload: param
});

export const setMissionRankingData = (param) => ({
    type: PICTREE.SET_MISSION_RANKING_DATA,
    payload: param
});

// PicTree - Reward Coin
export const getRewardCoinSummary = (param) => ({
    type: PICTREE.GET_REWARD_COIN_SUMMARY_DATA,
    payload: param
});

export const setRewardCoinSummary = (param) => ({
    type: PICTREE.SET_REWARD_COIN_SUMMARY_DATA,
    payload: param
});

export const getRewardCoinData = (param) => ({
    type: PICTREE.GET_REWARD_COIN_DATA,
    payload: param
});

export const setRewardCoinData = (param) => ({
    type: PICTREE.SET_REWARD_COIN_DATA,
    payload: param
});

export const getRewardCoinRankingData = (param) => ({
    type: PICTREE.GET_REWARD_COIN_RANKING_DATA,
    payload: param
});

export const setRewardCoinRankingData = (param) => ({
    type: PICTREE.SET_REWARD_COIN_RANKING_DATA,
    payload: param
});


// A2E
export const getA2ECampaigns = () => ({
    type: A2E.GET_A2E_CAMPAIGNS,
});

export const setA2ECampaigns = (param) => ({
    type: A2E.SET_A2E_CAMPAIGNS,
    payload: param
});

export const getA2ECampaignMetaById = (param) => ({
    type: A2E.GET_A2E_CAMPAIGN_META_BY_ID,
    payload: param
});

export const setA2ECampaignSelect = (param) => ({
    type: A2E.SET_A2E_CAMPAIGN_SELECT,
    payload: param
});

export const setA2ECustomDateRange = (param) => ({
    type: A2E.SET_CUSTOM_DATE_RANGE,
    payload: param
});

export const setA2EAlias = (param) => ({
    type: A2E.SET_ALIAS,
    payload: param
});

export const setA2EReportTab = (param) => ({
    type: A2E.SET_REPORT_TAB,
    payload: param
});

export const getA2EDataSummary = (param) => ({
    type: A2E.GET_A2E_DATA_SUMMARY,
    payload: param
});

export const getA2EDataByDate = (param) => ({
    type: A2E.GET_A2E_DATA_BY_DATE,
    payload: param
});

export const getA2EDataByCreative = (param) => ({
    type: A2E.GET_A2E_DATA_BY_CREATIVE,
    payload: param
});

export const getA2EDataByQuiz = (param) => ({
    type: A2E.GET_A2E_DATA_BY_QUIZ,
    payload: param
});

export const setA2EDataSummary = (param) => ({
    type: A2E.SET_A2E_DATA_SUMMARY,
    payload: param
});

export const setA2EDataByDate = (param) => ({
    type: A2E.SET_A2E_DATA_BY_DATE,
    payload: param
});

export const setA2EDataByCreative = (param) => ({
    type: A2E.SET_A2E_DATA_BY_CREATIVE,
    payload: param
});

export const setA2EDataByQuiz = (param) => ({
    type: A2E.SET_A2E_DATA_BY_QUIZ,
    payload: param
});

export const setA2ETabDataLoader = (param) => ({
    type: A2E.SET_TAB_DATA_LOADER,
    payload: param
});