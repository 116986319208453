import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Chip } from '@material-ui/core';
import moment from 'moment-timezone';
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    tableHead: {
      height: 48,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 32,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableRowNone: {
      height: 126,
    },
    tblHead: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 500,
    },
    tblHeadLeft: {
      textAlign: 'left',
      fontSize: 12,
      fontWeight: 500,
    },
    tblDataCenter: {
      textAlign: 'center',
    },
    tblDataLeft: {
      textAlign: 'left',
    },
    customTitle: {
      fontSize: 16,
      color: "#000",
    },
  })
);

function setChipLabel(label, classes) {
  const colorKeyName =
    label.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
  return <Chip className={classes[colorKeyName]} size="small" label={label} />;
}

const MissionTable = ({
  missionTableData,
  missionTableDataMui,
}) => {
  const classes = useStyles();

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション名",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span style={{minWidth: 120}} className={classes.tblHeadLeft} >ミッション名</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataLeft}>{value}</div>;
        },
      },
    },
    {
      name: "開始終了日時",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >開始終了<br />日時</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "時間",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >時間</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "初回達成所要(分)",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >初回達成所要(分)</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション達成人数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >達成者数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "ミッション参加者数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >参加者数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "撮影枚数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >撮影枚数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
    {
      name: "撮影基数",
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: (columnMeta) => ( <span className={classes.tblHead} >撮影基数</span> ),
        customBodyRender: (value) => {
          return <div className={classes.tblDataCenter}>{value}</div>;
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    filter: false,
    pagination: true,
    search: true,
    print: false,
    download: true,
    viewColumns: false,
    downloadOptions: {
      filename: "開催ミッションデータ",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    selectableRows: false,
    rowsPerPage: 20,
    textLabels: {
      body: {
        noMatch: "データを取得中です。。",
        toolTip: "",
      },
      toolbar: {
        search: "検索",
        viewColumns: "列の表示",
        downloadCsv: "CSVダウンロード",
        filterTable: "テーブルのフィルター",
      },
      viewColumns: {
        title: "表示する列",
        titleAria: "テーブルの列の表示/非表示",
      },
      pagination: {
        next: "次のページ",
        previous: "前のページ",
        rowsPerPage: "表示件数",
      },
      filter: {
        all: "全て",
        title: "フィルター",
        reset: "リセット",
      },
      selectedRows: {
        text: "レコードを選択",
        delete: "削除",
        deleteAria: "選択したレコードを削除する",
      },
    },
    rowsPerPageOptions: [20, 50, 100],
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <MUIDataTable
          title={<span className={classes.customTitle}>開催ミッション</span>}
          data={missionTableDataMui}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

MissionTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  missionTableData: state.pictree.missionSummaryData.missionTableData,
  missionTableDataMui: state.pictree.missionSummaryData.missionTableDataMui,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(MissionTable));
