import React, { useState, useEffect } from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import {
  setDateRangePicTree,
  setBtnStateDateFetch,
} from "actions";
import seasonConfig from "utils/pictree/SeasonDataConfig";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

let preDefinedEndDate = moment().tz("Asia/Tokyo").format("YYYY/MM/DD");

const SelectDateRangesPicTree = ({
  page,
  setDateRangePicTree,
  setBtnStateDateFetch,
  getDayDiff,
  setComparisonDateDiff,
  setPrevDateRange,
  getPrevDates,
  dateRangePicTree,
}) => {
  const classes = useStyles();
  const [range, setRange] = useState(7);

  useEffect(() => {
    setRange(7);
  }, []);

  useEffect(() => {
    const season = getSeason(dateRangePicTree);
    if (season === null) {
      const isPreDefined = isCustomPredefined(dateRangePicTree);
      if (isPreDefined) {
        setRange(isPreDefined);
      } else {
        if (dateRangePicTree[0] !== "2024/04/13" &&
            dateRangePicTree[1] !== moment().tz("Asia/Tokyo").format("YYYY/MM/DD")
        ) {
          setRange("");
        } else {
          setRange("all");
        }
      }
    } else {
      setRange(season);
    }

  }, [dateRangePicTree]);

  const getSeason = (selectedDates) => {
    for (let config of seasonConfig.seasonConfig) {
      if (
        moment(selectedDates[0]).format("YYYY/MM/DD") === moment(config.dateRange[0]).format("YYYY/MM/DD") &&
        moment(selectedDates[1]).format("YYYY/MM/DD") === moment(config.dateRange[1]).format("YYYY/MM/DD")
      ) {
        return typeof config.season !== "number" ? config.season : `season-${config.season}`;
      }
    }
    return null; // If no matching date range is found
  };

  const isCustomPredefined = (dateRangeSelected) => {
    const dateStart = dateRangeSelected[0].replace(/-/g, "/");
    const dateEnd = dateRangeSelected[1].replace(/-/g, "/");

    let isMatched = false;
    const jstEndDateToFetch = moment()
      .tz("Asia/Tokyo")
      .format("YYYY/MM/DD");

    let jstStartDateArray = [];
    jstStartDateArray.push(
      moment().tz("Asia/Tokyo").subtract(6, "days").format("YYYY/MM/DD")
    );
    _.forEach(seasonConfig.seasonConfig, function(seasonData) {
      jstStartDateArray.push(
        moment(seasonData["dateRange"][0]).tz("Asia/Tokyo").format("YYYY/MM/DD")
      );
    });

    if (jstStartDateArray.indexOf(dateStart) !== -1) {
      if (dateEnd === jstEndDateToFetch) {
        switch (jstStartDateArray.indexOf(dateStart)) {
          case 0:
            isMatched = 7;
            break;
          default:
        }
      }
    }
    return isMatched;
  };

  const handleChange = (event) => {
    setRange(event.target.value);
    let preDefinedStartDate;
    const valueAdjusted = event.target.value.indexOf("season-") !== -1 ? Number(event.target.value.split("-")[1]) : event.target.value;
    const result = seasonConfig.seasonConfig.find(item => item.season === valueAdjusted);
    if (event.target.value !== "all") {
      preDefinedStartDate = moment(result["dateRange"][0]).format("YYYY/MM/DD");
      preDefinedEndDate =  moment(result["dateRange"][1]).format("YYYY/MM/DD");
    } else {
      // all
      preDefinedStartDate = "2024/04/13";
      preDefinedEndDate = moment().tz("Asia/Tokyo").format("YYYY/MM/DD");
      setRange("all");
    }

    const dateStart = preDefinedStartDate.replace(/-/g, "/");
    const dateEnd = preDefinedEndDate.replace(/-/g, "/");
    setDateRangePicTree([dateStart, dateEnd]);

    let dayDiff = getDayDiff(dateStart, dateEnd);
    setComparisonDateDiff(dayDiff);

    let prevDates = getPrevDates(dateStart, dateEnd, dayDiff);
    setPrevDateRange(prevDates);
    setBtnStateDateFetch(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          displayEmpty
          value={range}
          onChange={handleChange}
        >
          <MenuItem value="" disabled>期間を選択する</MenuItem>
          <MenuItem value={7}>直近の7日間</MenuItem>
          <MenuItem value={"東京本シーズン3"}>東京本シーズン3 : 2024-11-01 ~ 2024-11-30</MenuItem>
          <MenuItem value={"東京本シーズン2"}>東京本シーズン2 : 2024-10-01 ~ 2024-10-31</MenuItem>
          <MenuItem value={"東京本シーズン1"}>東京本シーズン1 : 2024-09-14 ~ 2024-09-30</MenuItem>
          <MenuItem value={"Preseason at Tokyo"}>東京プレシーズン : 2024-07-26 ~ 2024-09-05</MenuItem>
          <MenuItem value={"season-5"}>シーズン5 : 2024-06-15 ~ 2024-06-29</MenuItem>
          <MenuItem value={"season-4"}>シーズン4 : 2024-06-01 ~ 2024-06-14</MenuItem>
          <MenuItem value={"season-3"}>シーズン3 : 2024-05-18 ~ 2024-05-31</MenuItem>
          <MenuItem value={"season-2"}>シーズン2 : 2024-05-04 ~ 2024-05-17</MenuItem>
          <MenuItem value={"season-1"}>シーズン1 : 2024-04-13 ~ 2024-04-28</MenuItem>
          {page !== "pictree-map" &&
            <MenuItem value="all">全期間 : 2024-04-13 ~</MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  );
};

SelectDateRangesPicTree.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRangePicTree: state.page.dateRangePicTree,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTree: (param) => dispatch(setDateRangePicTree(param)),
  setBtnStateDateFetch: (param) => dispatch(setBtnStateDateFetch(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(SelectDateRangesPicTree));
