import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { HOME, PAGE, ENDPOINT } from '../constants';
import {
    setHomeTotalStatsData,
    setHomeTotalStatsByXDate,
    setHomeAcquisitionData,
    setHomeAcquisitionPrevData,
    setBtnStateDateFetch,
    setHomeActiveUsersData,
    setHomeActiveUsersGeoData,
    setHomeActiveUsersGeoChartData,
    setHomeAcquisitionLastTrendData,
    setHomeTierData,
    setHomeTierPrevData,
    setHomeTierTrendData,
    setHomeNftHolderData,
} from 'actions';
import {
    getJwtToken,
    getHomeTotalStats,
    getHomeTotalUsers,
    getDataFromRDS,
    getDataFromSxi,
} from 'api';
import * as H from 'helper';

export const lastSevenBeginDate = state => state.page.lastSevenBeginDate;
export const lastSevenEndDate = state => state.page.lastSevenEndDate;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const mauStartDate = state => state.page.mauDateRange[0];
export const mauEndDate = state => state.page.mauDateRange[1];
export const mauStartPrevDate = state => state.page.mauPrevDateRange[0];
export const mauEndPrevDate = state => state.page.mauPrevDateRange[1];
export const wauStartDate = state => state.page.wauDateRange[0];
export const wauEndDate = state => state.page.wauDateRange[1];
export const wauStartPrevDate = state => state.page.wauPrevDateRange[0];
export const wauEndPrevDate = state => state.page.wauPrevDateRange[1];

export function* handleHomeTotalStatsData() {
    try {
        const jwtToken = yield call(getJwtToken);
        let totalRegStats = {}, totalRegStatsByXDate = {};
        let loading = 'loading';
        totalRegStats.gamePmid = loading;
        totalRegStats.ads = loading;
        totalRegStats.org = loading;
        totalRegStats.tier1 = loading;
        totalRegStats.tier2 = loading;
        totalRegStats.tier3 = loading;
        totalRegStats.fakeReg = loading;
        yield put(setHomeTotalStatsData(totalRegStats));

        totalRegStatsByXDate.gamePmid = loading;
        totalRegStatsByXDate.ads = loading;
        totalRegStatsByXDate.org = loading;
        totalRegStatsByXDate.tier1 = loading;
        totalRegStatsByXDate.tier2 = loading;
        totalRegStatsByXDate.tier3 = loading;
        totalRegStatsByXDate.fakeReg = loading;
        yield put(setHomeTotalStatsByXDate(totalRegStatsByXDate));

        const dataLastSgtDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD'); // Last Singapore Date
        let dateFrom = yield select(startDate);
        dateFrom = moment(dateFrom).tz("Asia/Singapore").format('YYYY-MM-DD');
        let dateTo = yield select(endDate);
        dateTo = moment(dateTo).tz("Asia/Singapore").format('YYYY-MM-DD');

        const queryFakeRegData = {"query": "SELECT COUNT(*) as count FROM user WHERE mail_address REGEXP 'promail1.net|mailkept.com|mailkept.com|rcmails.com|isecv.com|qq.com|mailsac.com|relxv.com'"};
        const sqlQueryTotalUsers = {"query": `SELECT COUNT(*) as count FROM user`}
        const sqlQueryGetCurrentUsers = {"query": `SELECT tier, COUNT(*) as count FROM user GROUP BY tier ORDER BY tier`}
        const [dataTotalUser, dataTempHomeStats, dataTempFakeUsers] = yield all([
            call(getDataFromSxi, sqlQueryTotalUsers, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getHomeTotalStats, ENDPOINT.GET_HOME_TOTAL_STATS, jwtToken),
            call(getDataFromSxi, queryFakeRegData, ENDPOINT.GET_DATA_SXI, jwtToken)
        ]);

        const allStatsRegData = JSON.parse(dataTempHomeStats.body);
        const dataRegTotalAds = allStatsRegData[0]['ads'];
        const dataRegTotalOrg = dataTotalUser[0]['count'] - allStatsRegData[0]['ads'];
        const dataCurrentUsers = yield call(getDataFromSxi, sqlQueryGetCurrentUsers, ENDPOINT.GET_DATA_SXI, jwtToken);

        totalRegStats = {
            gamePmid: dataCurrentUsers[0]['count'] + dataCurrentUsers[1]['count'] + dataCurrentUsers[2]['count'],
            ads: dataRegTotalAds,
            org: dataRegTotalOrg,
            tier1: dataCurrentUsers[0]['count'],
            tier2: dataCurrentUsers[1]['count'],
            tier3: dataCurrentUsers[2]['count'],
            fakeReg: dataTempFakeUsers[0]['count'],
        }

        if (dateTo !== dataLastSgtDate) {
            let unixEndSelected = moment(dateTo+" 23:59:59+8:00").format('x');
            const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
            const sqlQueryRegInRange = H.SqlHelper.getRegByXDate({range: [rangeEnd]});
            let sqlQueryAdData = {"query": "SELECT COUNT(*) FROM RegistrationProd WHERE rid != 'organic' AND regDate < "+unixEndSelected, "database": "KpiDashboard"};
            const [dataTotalTierSabun, dataTotalAdSabun] = yield all([
                call(getDataFromSxi, sqlQueryRegInRange, ENDPOINT.GET_DATA_SXI, jwtToken),
                call(getHomeTotalUsers, sqlQueryAdData, ENDPOINT.GET_DATA_USERS, jwtToken),
            ]);

            let sumOfAllTier = 0, tier2AdjustedData = 0, tier3AdjustedData = 0;
            if (dataTotalTierSabun.length === 3) {
                sumOfAllTier = dataTotalTierSabun[0]['count'] + dataTotalTierSabun[1]['count'] + dataTotalTierSabun[2]['count'];
                tier3AdjustedData = dataTotalTierSabun[2]['count'];
                tier2AdjustedData = dataTotalTierSabun[1]['count'];
            }
            totalRegStatsByXDate = {
                gamePmid: sumOfAllTier,
                ads: Number(dataTotalAdSabun.body),
                org: sumOfAllTier - Number(dataTotalAdSabun.body),
                tier1: dataTotalTierSabun[0]['count'],
                tier2: tier2AdjustedData,
                tier3: tier3AdjustedData,
            }
            yield put(setHomeTotalStatsByXDate(totalRegStatsByXDate));
        } else {
            yield put(setHomeTotalStatsByXDate(totalRegStats));
        }

        yield put(setHomeTotalStatsData(totalRegStats));
    } catch (error) {
        console.log(error);
    }
}

export function* handleHomeAcquisitionData() {
    try {
        const jwtToken = yield call(getJwtToken);
        let acquisitionHomeDataReg = {}, acquisitionHomeDataRegPrev = {}, acquisitionHomeDataRegLastSeven = {};
        let loading = 'loading';
        yield put(setBtnStateDateFetch(false));

        acquisitionHomeDataReg.pmidRegTotal = loading;
        acquisitionHomeDataReg.pmidRegAdTotal = loading;
        acquisitionHomeDataReg.pmidRegOrgTotal = loading;
        yield put(setHomeAcquisitionData(acquisitionHomeDataReg));
        yield put(setHomeAcquisitionPrevData(acquisitionHomeDataReg));

        const dateFrom = yield select(startDate);
        const dateTo = yield select(endDate);

        // DATA2.0
        const isDataNew = moment(dateFrom).isAfter('2023-06-20') ? true : false;
        let unixDateFrom = moment(dateFrom+" 00:00:00+8:00").format('x');
        let unixDateTo = moment(dateTo+" 23:59:59+8:00").format('x');

        // Previous Period
        const datePrevFrom = yield select(startPrevDate);
        const datePrevTo = yield select(endPrevDate);
        const isDataNewPrev = moment(datePrevFrom).isAfter('2023-06-20') ? true : false;
        let unixPrevDateFrom = moment(datePrevFrom+" 00:00:00+8:00").format('x');
        let unixPrevDateTo = moment(datePrevTo+" 23:59:59+8:00").format('x');

        // Last 7 days
        const dateLastFrom = yield select(lastSevenBeginDate);
        const dateLastTo = yield select(lastSevenEndDate);

        let unixLastBeginDateFrom = moment(dateLastFrom+" 00:00:00+8:00").format('x');
        let unixLastEndDateFrom = moment(dateLastTo+" 23:59:59+8:00").format('x');

        unixDateFrom = Number(unixDateFrom);
        unixDateTo = Number(unixDateTo);
        unixPrevDateFrom = Number(unixPrevDateFrom);
        unixPrevDateTo = Number(unixPrevDateTo);
        unixLastBeginDateFrom = Number(unixLastBeginDateFrom);
        unixLastEndDateFrom = Number(unixLastEndDateFrom);

        const regTable = isDataNew ? "RegistrationPmEvtProd" : "RegistrationProd";
        const sqlQueryAllReg = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regTable} WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAdReg = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regTable} WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND rid NOT IN (\"Organic\")`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryOrgReg = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regTable} WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND rid IN (\"Organic\")`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Prev
        const regPrevTable = isDataNewPrev ? "RegistrationPmEvtProd" : "RegistrationProd";
        const sqlQueryAllRegPrev = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regPrevTable} WHERE regDate BETWEEN ${unixPrevDateFrom} AND ${unixPrevDateTo}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAdRegPrev = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regPrevTable} WHERE regDate BETWEEN ${unixPrevDateFrom} AND ${unixPrevDateTo} AND rid NOT IN (\"Organic\")`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryOrgRegPrev = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS PMID FROM ${regPrevTable} WHERE regDate BETWEEN ${unixPrevDateFrom} AND ${unixPrevDateTo} AND rid IN (\"Organic\")`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Last 7
        const sqlQueryAllRegLastSeven = {
            "query": "SELECT DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS PMID FROM "+regTable+" WHERE regDate BETWEEN "+unixLastBeginDateFrom+" AND "+unixLastEndDateFrom+" GROUP BY DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore'));",
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAdsRegLastSeven = {
            "query": "SELECT DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS PMID FROM "+regTable+" WHERE regDate BETWEEN "+unixLastBeginDateFrom+" AND "+unixLastEndDateFrom+" AND rid NOT IN (\"Organic\") GROUP BY DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore'));",
            "database": "KpiDashboard",
            "type": "list"
        }
        const sqlQueryOrgRegLastSeven = {
            "query": "SELECT DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS PMID FROM "+regTable+" WHERE regDate BETWEEN "+unixLastBeginDateFrom+" AND "+unixLastEndDateFrom+" AND rid = 'Organic' GROUP BY DATE(convert_tz(FROM_UNIXTIME(regDate/1000), 'UTC', 'Asia/Singapore'));",
            "database": "KpiDashboard",
            "type": "list"
        }

        const [dataTempRegCount, dataTempRegByAd, dataTempRegByOrg,
               dataTempPrevRegCount, dataTempPrevRegByAd, dataTempPrevRegByOrg,
               dataTempLastRegAll, dataTempLastRegByAd, dataTempLastRegByOrg] = yield all([
            call(getHomeTotalUsers, sqlQueryAllReg, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryAdReg, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryOrgReg, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryAllRegPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryAdRegPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryOrgRegPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryAllRegLastSeven, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryAdsRegLastSeven, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryOrgRegLastSeven, ENDPOINT.GET_DATA_USERS, jwtToken)
        ]);

        const dataRegCount = JSON.parse(dataTempRegCount.body);
        const dataRegByAd = JSON.parse(dataTempRegByAd.body);
        const dataRegByOrg = JSON.parse(dataTempRegByOrg.body);
        const dataPrevRegCount = JSON.parse(dataTempPrevRegCount.body);
        const dataPrevRegByAd = JSON.parse(dataTempPrevRegByAd.body);
        const dataPrevRegByOrg = JSON.parse(dataTempPrevRegByOrg.body);
        const dataAllRegByDate = JSON.parse(dataTempLastRegAll.body);
        const dataAdsRegByDate = JSON.parse(dataTempLastRegByAd.body);
        const dataOrgRegByDate = JSON.parse(dataTempLastRegByOrg.body);

        let dataAllRegByDateArray = [], dataAdsRegByDateArray = [], dataOrgRegByDateArray = [];
        _.each(dataAllRegByDate, function(data, index) {
            if (index > 0) {
                dataAllRegByDateArray.push(data[1]['longValue']);
            }
        });

        _.each(dataAdsRegByDate, function(data, index) {
            if (index > 0) {
                dataAdsRegByDateArray.push(data[1]['longValue']);
            }
        });

        _.each(dataOrgRegByDate, function(data, index) {
            if (index > 0) {
                dataOrgRegByDateArray.push(data[1]['longValue']);
            }
        });

        const range = moment.range(dateLastFrom, dateLastTo);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('MM/DD/YY'));

        acquisitionHomeDataReg = {
            pmidRegTotal: dataRegCount[0][0]["longValue"],
            pmidRegAdTotal: dataRegByAd[0][0]["longValue"],
            pmidRegOrgTotal: dataRegByOrg[0][0]["longValue"],
        }
        acquisitionHomeDataRegPrev = {
            pmidRegTotal: dataPrevRegCount[0][0]["longValue"],
            pmidRegAdTotal: dataPrevRegByAd[0][0]["longValue"],
            pmidRegOrgTotal: dataPrevRegByOrg[0][0]["longValue"],
        }
        acquisitionHomeDataRegLastSeven = {
            dateRange: dateArray,
            allReg: dataAllRegByDateArray,
            byAds: dataAdsRegByDateArray,
            byOrg: dataOrgRegByDateArray
        }

        yield put(setHomeAcquisitionData(acquisitionHomeDataReg));
        yield put(setHomeAcquisitionPrevData(acquisitionHomeDataRegPrev));
        yield put(setHomeAcquisitionLastTrendData(acquisitionHomeDataRegLastSeven));
    } catch (error) {
        console.log(error);
    }
}

export function* handleHomeActiveUsersData() {
    try {
        // Initiate the spinning loader
        let activeUserHomeData = {
            playMining: { dau: 'loading' },
            jobTribes: { dau: 'loading' },
            depMarketplace: { dau: 'loading' },
            luckyFarmer: { dau: 'loading' },
            cookinBurger: { dau: 'loading' },
        };
        yield put(setHomeActiveUsersData(activeUserHomeData));

        let loadingStateObject = {
            jobTribes: ['loading'],
            depMarketplace: ['loading'],
            luckyFarmer: ['loading'],
            cookinBurger: ['loading'],
        };
        yield put(setHomeActiveUsersGeoData(loadingStateObject));
        yield put(setHomeActiveUsersGeoChartData(loadingStateObject));

        // Define date, time range
        const jwtToken = yield call(getJwtToken);

        // DATA2.0 switch date: 2023-06-20
        // DAU Period
        let dateFrom = yield select(endDate);
        let dateTo = yield select(endDate);
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');

        const isDataNew = moment(dateFrom).isBefore('2023-06-20') ? false : true;
        let unixDateFrom2 = moment(dateFrom+" 00:00:00+8:00").format('x');
        let unixDateTo = moment(dateTo+" 23:59:59+8:00").format('x');

        let unixDateFrom2Cb = unixDateFrom2;
        let unixDateToCb = unixDateTo;
        if (isDataNew) {
            unixDateFrom2Cb = unixDateFrom2/1000;
            unixDateToCb = unixDateTo/1000;
        }

        const datePrevFrom = yield select(startPrevDate);
        let newPrevDate = moment(dateTo).subtract(1, 'days').format('YYYY/MM/DD');
        let newUnixPrevDateFrom = moment(newPrevDate+" 00:00:00+8:00").format('x');
        let newUnixPrevDateTo = moment(newPrevDate+" 23:59:59+8:00").format('x');

        let newUnixPrevDateFromCb = newUnixPrevDateFrom;
        let newUnixPrevDateToCb = newUnixPrevDateTo;
        if (isDataNew) {
            newUnixPrevDateFromCb = newUnixPrevDateFrom/1000;
            newUnixPrevDateToCb = newUnixPrevDateTo/1000;
        }

        // WAU Period
        const dateWauFrom = yield select(wauStartDate);
        const dateWauTo = yield select(wauEndDate);
        const unixWauDateFrom = moment(dateWauFrom+" 00:00:00+8:00").format('x');
        const unixWauDateTo = moment(dateWauTo+" 23:59:59+8:00").format('x');
        const dateWauPrevFrom = yield select(wauStartPrevDate);
        const dateWauPrevTo = yield select(wauEndPrevDate);
        const unixWauPrevDateFrom = moment(dateWauPrevFrom+" 00:00:00+8:00").format('x');
        const unixWauPrevDateTo = moment(dateWauPrevTo+" 23:59:59+8:00").format('x');

        // check if the period is mixed of before and after
        let tableFlagWau = 'new';
        if (moment(dateWauTo).isBefore('2023-06-20')) {
            // sets old table
            tableFlagWau = 'old';
        } else if (moment(dateWauFrom).isAfter('2023-06-20')) {
            // use all new table
            tableFlagWau = 'new';
        } else if (moment(dateWauFrom).isBefore('2023-06-20') &&
                   moment(dateWauTo).isAfter('2023-06-20')) {
            // use both tables ideally
            tableFlagWau = 'new';
        }

        let tableFlagWauPrev = 'new';
        if (moment(dateWauPrevTo).isBefore('2023-06-20')) {
            // sets old table
            tableFlagWauPrev = 'old';
        } else if (moment(dateWauPrevFrom).isAfter('2023-06-20')) {
            // use all new table
            tableFlagWauPrev = 'new';
        } else if (moment(dateWauPrevFrom).isBefore('2023-06-20') &&
                   moment(dateWauPrevTo).isAfter('2023-06-20')) {
            // use both tables ideally
            tableFlagWauPrev = 'new';
        }

        // DAU BEGIN
        // NFT:DEP
        const dbTableNftDep = isDataNew ? 'DaaMemberActionVisitPmEvtProd' : 'DaaMemberActionVisitProd';
        const sqlQueryNftDepDauData = {
            "query": "SELECT COUNT(DISTINCT(pmId)) AS count FROM "+dbTableNftDep+" WHERE screen != '1200x600' AND logDate BETWEEN "+unixDateFrom2+" AND "+unixDateTo,
            "database": "KpiDashboard",
            "type": "list"
        }
        const sqlQueryNftDepDauPrevData = {
            "query": "SELECT COUNT(DISTINCT(pmId)) AS count FROM "+dbTableNftDep+" WHERE screen != '1200x600' AND logDate BETWEEN "+newUnixPrevDateFrom+" AND "+newUnixPrevDateTo,
            "database": "KpiDashboard",
            "type": "list"
        }

        const dbTableJtcb = isDataNew ? 'JtcbGamePlayStartPmEvtProd' : 'JtcbGamePlayStartByPmidProd';
        const sqlQueryJtcbDauDataCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableJtcb+" WHERE logDate BETWEEN "+unixDateFrom2+" AND "+unixDateTo, "database": "KpiDashboard"};
        const sqlQueryJtcbDauDataPrevCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableJtcb+" WHERE logDate BETWEEN "+newUnixPrevDateFrom+" AND "+newUnixPrevDateTo, "database": "KpiDashboard"};

        const dbTableLf = isDataNew ? 'LuckyFarmerGamePlayStartV2PmEvtProd' : 'LuckyFarmerGamePlayStartProdV2';
        const sqlQueryLfDauDataCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableLf+" WHERE logDate BETWEEN "+unixDateFrom2+" AND "+unixDateTo, "database": "KpiDashboard"};
        const sqlQueryLfDauDataPrevCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableLf+" WHERE logDate BETWEEN "+newUnixPrevDateFrom+" AND "+newUnixPrevDateTo, "database": "KpiDashboard"};

        const dbTableCb = isDataNew ? 'CookinBurgerGamePlayStartPmEvtProd' : 'CookinBurgerGamePlayStartProd';
        const sqlQueryCbDauDataCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableCb+" WHERE logDate BETWEEN "+unixDateFrom2Cb+" AND "+unixDateToCb, "database": "KpiDashboard"};
        const sqlQueryCbDauDataPrevCount = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableCb+" WHERE logDate BETWEEN "+newUnixPrevDateFromCb+" AND "+newUnixPrevDateToCb, "database": "KpiDashboard"};

        // WAU BEGIN
        const dbTableNftDepWau = tableFlagWau === 'new' ? "DaaMemberActionVisitPmEvtProd" : "DaaMemberActionVisitProd";
        const sqlQueryNftDepWauData = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableNftDepWau} WHERE screen != '1200x600' AND logDate BETWEEN `+unixWauDateFrom+` AND `+unixWauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableJtcbWau = tableFlagWau === 'new' ? "JtcbGamePlayStartPmEvtProd" : "JtcbGamePlayStartByPmidProd";
        const sqlQueryJtcbWauData = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableJtcbWau} WHERE logDate BETWEEN `+unixWauDateFrom+` AND `+unixWauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableLfWau = tableFlagWau === 'new' ? "LuckyFarmerGamePlayStartV2PmEvtProd" : "LuckyFarmerGamePlayStartProdV2";
        const sqlQueryLfWauData = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableLfWau} WHERE logDate BETWEEN `+unixWauDateFrom+` AND `+unixWauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableCbWau = tableFlagWau === 'new' ? "CookinBurgerGamePlayStartPmEvtProd" : "CookinBurgerGamePlayStartProd";
        const unixWauDateFromCb = tableFlagWau === 'new' ? unixWauDateFrom/1000 : unixWauDateFrom;
        const unixWauDateToCb = tableFlagWau === 'new' ? unixWauDateTo/1000 : unixWauDateTo;
        const sqlQueryCbWauData = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableCbWau} WHERE logDate BETWEEN `+unixWauDateFromCb+` AND `+unixWauDateToCb+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }

        // WAU Prev
        const dbTableNftDepWauPrev = tableFlagWauPrev === 'new' ? "DaaMemberActionVisitPmEvtProd" : "DaaMemberActionVisitProd";
        const sqlQueryNftDepWauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableNftDepWauPrev+" WHERE screen != '1200x600' AND logDate BETWEEN "+unixWauPrevDateFrom+" AND "+unixWauPrevDateTo, "database": "KpiDashboard"};

        const dbTableJtcbWauPrev = tableFlagWauPrev === 'new' ? "JtcbGamePlayStartPmEvtProd" : "JtcbGamePlayStartByPmidProd";
        const sqlQueryJtcbWauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableJtcbWauPrev+" WHERE logDate BETWEEN "+unixWauPrevDateFrom+" AND "+unixWauPrevDateTo, "database": "KpiDashboard"};

        const dbTableLfWauPrev = tableFlagWauPrev === 'new' ? "LuckyFarmerGamePlayStartV2PmEvtProd" : "LuckyFarmerGamePlayStartProdV2";
        const sqlQueryLfWauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableLfWauPrev+" WHERE logDate BETWEEN "+unixWauPrevDateFrom+" AND "+unixWauPrevDateTo, "database": "KpiDashboard"};

        const dbTableCbWauPrev = tableFlagWauPrev === 'new' ? "CookinBurgerGamePlayStartPmEvtProd" : "CookinBurgerGamePlayStartProd";
        const unixWauDateFromCbPrev = tableFlagWau === 'new' ? unixWauPrevDateFrom/1000 : unixWauPrevDateFrom;
        const unixWauDateToCbPrev = tableFlagWau === 'new' ? unixWauPrevDateTo/1000 : unixWauPrevDateTo;
        const sqlQueryCbWauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableCbWauPrev+" WHERE logDate BETWEEN "+unixWauDateFromCbPrev+" AND "+unixWauDateToCbPrev, "database": "KpiDashboard"};

        const [
            tempNftDepDauCount,
            tempNftDepDauPrevCount,
            tempJtcbDauCount,
            tempJtcbDauPrevCount,
            tempLfDauCount,
            tempLfDauPrevCount,
            tempCbDauCount,
            tempCbDauPrevCount,
            tempNftDepWauCount,
            tempNftDepWauPrevCount,
            tempJtcbWauCount,
            tempJtcbWauPrevCount,
            tempLfWauCount,
            tempLfWauPrevCount,
            tempCbWauCount,
            tempCbWauPrevCount,
        ] = yield all([
            call(getDataFromRDS, sqlQueryNftDepDauData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryNftDepDauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryJtcbDauDataCount, ENDPOINT.GET_DATA_USERS, jwtToken),         // JTCB dau
            call(getHomeTotalUsers, sqlQueryJtcbDauDataPrevCount, ENDPOINT.GET_DATA_USERS, jwtToken),     // JTCB dau prev
            call(getHomeTotalUsers, sqlQueryLfDauDataCount, ENDPOINT.GET_DATA_USERS, jwtToken),           // Lf dau
            call(getHomeTotalUsers, sqlQueryLfDauDataPrevCount, ENDPOINT.GET_DATA_USERS, jwtToken),       // Lf dau prev
            call(getHomeTotalUsers, sqlQueryCbDauDataCount, ENDPOINT.GET_DATA_USERS, jwtToken),           // CookinBurger dau
            call(getHomeTotalUsers, sqlQueryCbDauDataPrevCount, ENDPOINT.GET_DATA_USERS, jwtToken),       // CookinBurger dau prev
            call(getHomeTotalUsers, sqlQueryNftDepWauData, ENDPOINT.GET_DATA_USERS, jwtToken),            // nftDep wau
            call(getHomeTotalUsers, sqlQueryNftDepWauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),        // nftDep wau prev
            call(getHomeTotalUsers, sqlQueryJtcbWauData, ENDPOINT.GET_DATA_USERS, jwtToken),              // JTCB wau
            call(getHomeTotalUsers, sqlQueryJtcbWauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),          // JTCB wau prev
            call(getHomeTotalUsers, sqlQueryLfWauData, ENDPOINT.GET_DATA_USERS, jwtToken),                // Lf wau
            call(getHomeTotalUsers, sqlQueryLfWauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),            // Lf wau prev
            call(getHomeTotalUsers, sqlQueryCbWauData, ENDPOINT.GET_DATA_USERS, jwtToken),                // Cb wau
            call(getHomeTotalUsers, sqlQueryCbWauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),            // Cb wau prev
        ]);

        // // NFT:DEP
        const isPrevDataAvail = moment(datePrevFrom).isAfter('2021-05-25');
        const tempNftDepDauCountParsed = JSON.parse(tempNftDepDauCount.body);
        const depMarketplaceDauDaily = tempNftDepDauCountParsed[0][0]['longValue'];
        const dataTempPrevUUDepMarketParsed = JSON.parse(tempNftDepDauPrevCount.body);
        const depMarketplaceDauPrevDaily = isPrevDataAvail ? dataTempPrevUUDepMarketParsed[0][0]['longValue'] : false;
        // DAU END

        let nftDepWauTrend = JSON.parse(tempNftDepWauCount.body);
        const sumOfNftDepWau = Number(_.sumBy(nftDepWauTrend, d => {return d[1].longValue})).toFixed(0);

        let jtcbWauTrend = JSON.parse(tempJtcbWauCount.body);
        const sumOfJtcbWau = Number(_.sumBy(jtcbWauTrend, d => {return d[1].longValue})).toFixed(0);

        let LfWauTrend = JSON.parse(tempLfWauCount.body);
        const sumOfLfWau = Number(_.sumBy(LfWauTrend, d => {return d[1].longValue})).toFixed(0);

        let CbWauTrend = JSON.parse(tempCbWauCount.body);
        const sumOfCbWau = Number(_.sumBy(CbWauTrend, d => {return d[1].longValue})).toFixed(0);

        // WAU trend : NFT:DEP
        let dataCountNftDepWauByDate = [];
        for (let i=0; i<nftDepWauTrend.length;i++) {
            dataCountNftDepWauByDate.push(nftDepWauTrend[i][1]['longValue']);
        }

        // WAU trend : JTCB
        let dataCountJtcbWauByDate = [];
        for (let i=0; i<jtcbWauTrend.length;i++) {
            dataCountJtcbWauByDate.push(jtcbWauTrend[i][1]['longValue']);
        }

        // WAU trend : LuckyFarmer
        let dataCountLfWauByDate = [];
        for (let i=0; i<LfWauTrend.length;i++) {
            dataCountLfWauByDate.push(LfWauTrend[i][1]['longValue']);
        }

        // WAU trend : CookinBurger
        let dataCountCbWauByDate = [];
        for (let i=0; i<CbWauTrend.length;i++) {
            dataCountCbWauByDate.push(CbWauTrend[i][1]['longValue']);
        }

        // WAU Trend Date
        const wauRange = moment.range(dateWauFrom, dateWauTo);
        const wauDays = Array.from(wauRange.by('days'));
        const wauDateArray = wauDays.map(m => m.format('MM/DD/YY'));
        // WAU END

        // MAU BEGIN
        const dateMauFrom = yield select(mauStartDate);
        const dateMauTo = yield select(mauEndDate);
        const unixMauDateFrom = moment(dateMauFrom+" 00:00:00+8:00").format('x');
        const unixMauDateTo = moment(dateMauTo+" 23:59:59+8:00").format('x');
        const dateMauPrevFrom = yield select(mauStartPrevDate);
        const dateMauPrevTo = yield select(mauEndPrevDate);
        const unixMauPrevDateFrom = moment(dateMauPrevFrom+" 00:00:00+8:00").format('x');
        const unixMauPrevDateTo = moment(dateMauPrevTo+" 23:59:59+8:00").format('x');

        // check if the period is mixed of before and after
        let tableFlagMau = 'new';
        if (moment(dateMauTo).isBefore('2023-06-20')) {
            // sets old table
            tableFlagMau = 'old';
        } else if (moment(dateMauFrom).isAfter('2023-06-20')) {
            // use all new table
            tableFlagMau = 'new';
        } else if (moment(dateMauFrom).isBefore('2023-06-20') &&
                   moment(dateMauTo).isAfter('2023-06-20')) {
            // use both tables ideally
            tableFlagMau = 'new';
        }

        let tableFlagMauPrev = 'new';
        if (moment(dateMauPrevTo).isBefore('2023-06-20')) {
            // sets old table
            tableFlagMauPrev = 'old';
        } else if (moment(dateMauPrevFrom).isAfter('2023-06-20')) {
            // use all new table
            tableFlagMauPrev = 'new';
        } else if (moment(dateMauPrevFrom).isBefore('2023-06-20') &&
                   moment(dateMauPrevTo).isAfter('2023-06-20')) {
            // use both tables ideally
            tableFlagMauPrev = 'new';
        }

        const dbTableNftDepMau = tableFlagMau === 'new' ? "DaaMemberActionVisitPmEvtProd" : "DaaMemberActionVisitProd";
        const sqlQueryNftDepMauTrend = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableNftDepMau} WHERE screen != '1200x600' AND logDate BETWEEN `+unixMauDateFrom+` AND `+unixMauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableJtcbMau = tableFlagMau === 'new' ? "JtcbGamePlayStartPmEvtProd" : "JtcbGamePlayStartByPmidProd";
        const sqlQueryJtcbMauTrend = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableJtcbMau} WHERE logDate BETWEEN `+unixMauDateFrom+` AND `+unixMauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableLfMau = tableFlagMau === 'new' ? "LuckyFarmerGamePlayStartV2PmEvtProd" : "LuckyFarmerGamePlayStartProdV2";
        const sqlQueryLfMauTrend = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableLfMau} WHERE logDate BETWEEN `+unixMauDateFrom+` AND `+unixMauDateTo+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        const dbTableCbMau = tableFlagMau === 'new' ? "CookinBurgerGamePlayStartPmEvtProd" : "CookinBurgerGamePlayStartProd";
        const unixMauDateFromCb = tableFlagWau === 'new' ? unixMauDateFrom/1000 : unixMauDateFrom;
        const unixMauDateToCb = tableFlagWau === 'new' ? unixMauDateTo/1000 : unixMauDateTo;
        const sqlQueryCbMauTrend = {
            "query": `SELECT date, COUNT(date) as count FROM (SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date FROM ${dbTableCbMau} WHERE logDate BETWEEN `+unixMauDateFromCb+` AND `+unixMauDateToCb+` GROUP BY pmId) as totalCount GROUP BY date;`,
            "database": "KpiDashboard",
            "type": "list"
        }

        // MAU Prev
        const dbTableNftDepMauPrev = tableFlagMauPrev === 'new' ? "DaaMemberActionVisitPmEvtProd" : "DaaMemberActionVisitProd";
        const sqlQueryNftDepMauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableNftDepMauPrev+" WHERE screen != '1200x600' AND logDate BETWEEN "+unixMauPrevDateFrom+" AND "+unixMauPrevDateTo, "database": "KpiDashboard"};
        const dbTableJtcbMauPrev = tableFlagMauPrev === 'new' ? "JtcbGamePlayStartPmEvtProd" : "JtcbGamePlayStartByPmidProd";
        const sqlQueryJtcbMauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableJtcbMauPrev+" WHERE logDate BETWEEN "+unixMauPrevDateFrom+" AND "+unixMauPrevDateTo, "database": "KpiDashboard"};
        const dbTableLfMauPrev = tableFlagMauPrev === 'new' ? "LuckyFarmerGamePlayStartV2PmEvtProd" : "LuckyFarmerGamePlayStartProdV2";
        const sqlQueryLfMauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableLfMauPrev+" WHERE logDate BETWEEN "+unixMauPrevDateFrom+" AND "+unixMauPrevDateTo, "database": "KpiDashboard"};
        const dbTableCbMauPrev = tableFlagMauPrev === 'new' ? "CookinBurgerGamePlayStartPmEvtProd" : "CookinBurgerGamePlayStartProd";
        const unixMauDateFromCbPrev = tableFlagMauPrev === 'new' ? unixMauPrevDateFrom/1000 : unixMauPrevDateFrom;
        const unixMauDateToCbPrev = tableFlagMauPrev === 'new' ? unixMauPrevDateTo/1000 : unixMauPrevDateTo;
        const sqlQueryCbMauPrevData = {"query": "SELECT COUNT(DISTINCT(pmId)) FROM "+dbTableCbMauPrev+" WHERE logDate BETWEEN "+unixMauDateFromCbPrev+" AND "+unixMauDateToCbPrev, "database": "KpiDashboard"};

        // GEO data fetch
        const dbTableNftDepGeo = isDataNew ? 'DaaMemberActionVisitPmEvtProd' : 'DaaMemberActionVisitProd';
        const sqlQueryNftDepGeo = {
            "query": "SELECT country, COUNT(*) AS count FROM "+dbTableNftDepGeo+" WHERE screen != '1200x600' AND logDate BETWEEN "+unixDateFrom2+" AND "+unixDateTo+" GROUP BY country ORDER BY count DESC LIMIT 10",
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryUuCountByCountryJtcb = H.SqlHelper.getUniqueUserCountByCountry('jobtribes', unixDateFrom2, unixDateTo, 10, isDataNew);
        const sqlQueryUuCountByCountryLuckyF = H.SqlHelper.getUniqueUserCountByCountry('luckyfarmer', unixDateFrom2, unixDateTo, 10, isDataNew);
        const sqlQueryUuCountByCountryCookinB = H.SqlHelper.getUniqueUserCountByCountry('cookinburger', unixDateFrom2, unixDateTo, 10, isDataNew);

        const [
            tempNftDepMauTrend,
            tempNftDepMauPrevCount,
            tempJtcbMauTrend,
            tempJtcbMauPrevCount,
            tempLfMauTrend,
            tempLfMauPrevCount,
            tempCbMauTrend,
            tempCbMauPrevCount,
            dataTempGeoDepMarket,
            dataTempGeoJobT,
            dataTempGeoLuckyFarmer,
            dataTempGeoCookinBurger,
        ] = yield all([
            call(getHomeTotalUsers, sqlQueryNftDepMauTrend, ENDPOINT.GET_DATA_USERS, jwtToken),     // NftDep mau and Trend
            call(getHomeTotalUsers, sqlQueryNftDepMauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),  // NftDep mau prev
            call(getHomeTotalUsers, sqlQueryJtcbMauTrend, ENDPOINT.GET_DATA_USERS, jwtToken),       // JTCB mau and Trend
            call(getHomeTotalUsers, sqlQueryJtcbMauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),    // JTCB mau prev
            call(getHomeTotalUsers, sqlQueryLfMauTrend, ENDPOINT.GET_DATA_USERS, jwtToken),         // Lf mau and Trend
            call(getHomeTotalUsers, sqlQueryLfMauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),      // Lf mau prev
            call(getHomeTotalUsers, sqlQueryCbMauTrend, ENDPOINT.GET_DATA_USERS, jwtToken),         // Cb mau and Trend
            call(getHomeTotalUsers, sqlQueryCbMauPrevData, ENDPOINT.GET_DATA_USERS, jwtToken),      // Cb mau prev
            call(getDataFromRDS, sqlQueryNftDepGeo, ENDPOINT.GET_DATA_USERS, jwtToken),             // GEO NFT:DEP
            call(getDataFromRDS, sqlQueryUuCountByCountryJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),    // GEO JTCB
            call(getDataFromRDS, sqlQueryUuCountByCountryLuckyF, ENDPOINT.GET_DATA_RDS, jwtToken),  // GEO Lf
            call(getDataFromRDS, sqlQueryUuCountByCountryCookinB, ENDPOINT.GET_DATA_RDS, jwtToken),  // GEO CookinBurger
        ]);

        let nftDepMauTrend = JSON.parse(tempNftDepMauTrend.body);
        const sumOfNftDepTrend = Number(_.sumBy(nftDepMauTrend, d => {return d[1].longValue})).toFixed(0);

        let jtcbMauTrend = JSON.parse(tempJtcbMauTrend.body);
        const sumOfJtcbTrend = Number(_.sumBy(jtcbMauTrend, d => {return d[1].longValue})).toFixed(0);

        let LfMauTrend = JSON.parse(tempLfMauTrend.body);
        const sumOfLfTrend = Number(_.sumBy(LfMauTrend, d => {return d[1].longValue})).toFixed(0);

        let CbMauTrend = JSON.parse(tempCbMauTrend.body);
        const sumOfCbTrend = Number(_.sumBy(CbMauTrend, d => {return d[1].longValue})).toFixed(0);

        // MAU trend : NFT:DEP
        let dataCountNftDepMauByDate = [];
        for (let i=0; i<nftDepMauTrend.length;i++) {
            dataCountNftDepMauByDate.push(nftDepMauTrend[i][1]['longValue']);
        }

        // MAU trend : JTCB
        let dataCountJtcbMauByDate = [];
        for (let i=0; i<jtcbMauTrend.length;i++) {
            dataCountJtcbMauByDate.push(jtcbMauTrend[i][1]['longValue']);
        }

        // MAU trend : LuckyFarmer
        let dataCountLfMauByDate = [];
        for (let i=0; i<LfMauTrend.length;i++) {
            dataCountLfMauByDate.push(LfMauTrend[i][1]['longValue']);
        }

        // MAU trend : CookinBurger
        let dataCountCbMauByDate = [];
        for (let i=0; i<CbMauTrend.length;i++) {
            dataCountCbMauByDate.push(CbMauTrend[i][1]['longValue']);
        }

        // MAU Trend Date
        const mauRange = moment.range(dateMauFrom, dateMauTo);
        const mauDays = Array.from(mauRange.by('days'));
        const mauDateArray = mauDays.map(m => m.format('MM/DD/YY'));
        // MAU END

        // PlayMining Total
        const pmDau = depMarketplaceDauDaily + Number(tempJtcbDauCount.body) + Number(tempLfDauCount.body) + Number(tempCbDauCount.body);
        const pmDauPrev = depMarketplaceDauPrevDaily + Number(tempJtcbDauPrevCount.body) + Number(tempLfDauPrevCount.body) + Number(tempCbDauPrevCount.body);
        const pmWau = Number(sumOfNftDepWau) + Number(sumOfJtcbWau) + Number(sumOfLfWau) + Number(sumOfCbWau);
        const pmWauPrev = Number(tempNftDepWauPrevCount.body) + Number(tempJtcbWauPrevCount.body) + Number(tempLfWauPrevCount.body) + Number(tempCbWauPrevCount.body);
        const pmWauMerge = _.map(_.unzip([dataCountNftDepWauByDate, dataCountJtcbWauByDate, dataCountLfWauByDate, dataCountCbWauByDate]), _.sum);
        const pmMau = Number(sumOfNftDepTrend) + Number(sumOfJtcbTrend) + Number(sumOfLfTrend) + Number(sumOfCbTrend);
        const pmMauPrev = Number(tempNftDepMauPrevCount.body) + Number(tempJtcbMauPrevCount.body) + Number(tempLfMauPrevCount.body) + Number(tempCbMauPrevCount.body);
        const pmMauMerge = _.map(_.unzip([dataCountNftDepMauByDate, dataCountJtcbMauByDate, dataCountLfMauByDate, dataCountCbMauByDate]), _.sum);

        // Assign all data
        activeUserHomeData = {
            playMining: {
                dau: pmDau,
                dauPrev: pmDauPrev,
                wau: pmWau,
                wauPrev: pmWauPrev,
                wauLastSeven: {
                    dateRange: wauDateArray,
                    data: pmWauMerge
                },
                mau: Number(pmMau),
                mauPrev: Number(pmMauPrev),
                mauLastThirty: {
                    dateRange: mauDateArray,
                    data: pmMauMerge
                },
            },
            depMarketplace: {
                dau: depMarketplaceDauDaily,
                dauPrev: depMarketplaceDauPrevDaily,
                wau: sumOfNftDepWau,
                wauPrev: Number(tempNftDepWauPrevCount.body),
                wauLastSeven: {
                    dateRange: wauDateArray,
                    data: dataCountNftDepWauByDate
                },
                mau: sumOfNftDepTrend,
                mauPrev: Number(tempNftDepMauPrevCount.body),
                mauLastThirty: {
                    dateRange: mauDateArray,
                    data: dataCountNftDepMauByDate
                },
            },
            jobTribes: {
                dau: Number(tempJtcbDauCount.body),
                dauPrev: Number(tempJtcbDauPrevCount.body),
                wau: sumOfJtcbWau,
                wauPrev: Number(tempJtcbWauPrevCount.body),
                wauLastSeven: {
                    dateRange: wauDateArray,
                    data: dataCountJtcbWauByDate
                },
                mau: sumOfJtcbTrend,
                mauPrev: Number(tempJtcbMauPrevCount.body),
                mauLastThirty: {
                    dateRange: mauDateArray,
                    data: dataCountJtcbMauByDate
                },
            },
            luckyFarmer: {
                dau: Number(tempLfDauCount.body),
                dauPrev: Number(tempLfDauPrevCount.body),
                wau: sumOfLfWau,
                wauPrev: Number(tempLfWauPrevCount.body),
                wauLastSeven: {
                    dateRange: wauDateArray,
                    data: dataCountLfWauByDate
                },
                mau: sumOfLfTrend,
                mauPrev: Number(tempLfMauPrevCount.body),
                mauLastThirty: {
                    dateRange: mauDateArray,
                    data: dataCountLfMauByDate
                },
            },
            cookinBurger: {
                dau: Number(tempCbDauCount.body),
                dauPrev: Number(tempCbDauPrevCount.body),
                wau: sumOfCbWau,
                wauPrev: Number(tempCbWauPrevCount.body),
                wauLastSeven: {
                    dateRange: wauDateArray,
                    data: dataCountCbWauByDate
                },
                mau: sumOfCbTrend,
                mauPrev: Number(tempCbMauPrevCount.body),
                mauLastThirty: {
                    dateRange: mauDateArray,
                    data: dataCountCbMauByDate
                },
            },
        };
        yield put(setHomeActiveUsersData(activeUserHomeData));

        // GEO Map Data Parsing Begin
        //JobTribes
        let countryChartJtArray = [], dataChartJtArray = [];
        const dataReturnedJtcbUuCountByCountry = JSON.parse(dataTempGeoJobT.body);
        for (let i=0; i<dataReturnedJtcbUuCountByCountry.length; i++) {
            countryChartJtArray.push(dataReturnedJtcbUuCountByCountry[i][0]['stringValue']);
            dataChartJtArray.push(dataReturnedJtcbUuCountByCountry[i][1]['longValue']);
        }
        const totalGeoActiveJtUsers = _.sum(dataChartJtArray);
        let dataChartJtArrayPercent = [], dataChartJtArrayCount = [];
        let countryJtArray = [], countrySet = {};
        for (let j=0; j<dataChartJtArray.length; j++) {
            countrySet.country = countryChartJtArray[j];
            countrySet.count = dataChartJtArray[j];
            countryJtArray.push(countrySet);
            countrySet = {};
            dataChartJtArrayPercent.push((dataChartJtArray[j]/totalGeoActiveJtUsers * 100).toFixed(2));
            dataChartJtArrayCount.push(dataChartJtArray[j]);
        }

        //NFT:DEP
        const dataTempGeoDepMarketParsed = JSON.parse(dataTempGeoDepMarket.body);
        let countryChartMarketplaceArray = [], dataChartMarketplaceArray = [];
        for (let i=0; i<dataTempGeoDepMarketParsed.length; i++) {
            countryChartMarketplaceArray.push(dataTempGeoDepMarketParsed[i][0]['stringValue']);
            dataChartMarketplaceArray.push(dataTempGeoDepMarketParsed[i][1]['longValue']);
        }
        const totalGeoActiveMarketplaceUsers = _.sum(dataChartMarketplaceArray);

        let dataChartMarketplaceArrayPercent = [];
        let countryMarketplaceArray = [], dataChartNftDepArrayCount = [];
        countrySet = {};
        for (let k=0; k<countryChartMarketplaceArray.length; k++) {
            countrySet.country = dataTempGeoDepMarketParsed[k][0]['stringValue'];
            countrySet.count = dataChartMarketplaceArray[k];
            countryMarketplaceArray.push(countrySet);
            countrySet = {};
            dataChartMarketplaceArrayPercent.push((dataChartMarketplaceArray[k]/totalGeoActiveMarketplaceUsers * 100).toFixed(2));
            dataChartNftDepArrayCount.push(dataChartMarketplaceArray[k]);
        }

        //LuckyFarmer
        const dataTempGeoLuckyFarmerParsed = JSON.parse(dataTempGeoLuckyFarmer.body)
        let countryChartLuckyFarmerArray = [], dataChartLuckyFarmerArray = [];
        for (let i=0; i<dataTempGeoLuckyFarmerParsed.length; i++) {
            countryChartLuckyFarmerArray.push(dataTempGeoLuckyFarmerParsed[i][0]['stringValue']);
            dataChartLuckyFarmerArray.push(dataTempGeoLuckyFarmerParsed[i][1]['longValue']);
        }
        const totalGeoActiveLuckyFarmerUsers = _.sum(dataChartLuckyFarmerArray);

        let dataChartLuckyFarmerArrayPercent = [];
        let countryLuckyFarmerArray = [], countryLuckyFarmerArrayCount = [];
        countrySet = {};
        for (let k=0; k<countryChartLuckyFarmerArray.length; k++) {
            countrySet.country = dataTempGeoLuckyFarmerParsed[k][0]['stringValue'];
            countrySet.count = dataChartLuckyFarmerArray[k];
            countryLuckyFarmerArray.push(countrySet);
            countrySet = {};
            dataChartLuckyFarmerArrayPercent.push((dataChartLuckyFarmerArray[k]/totalGeoActiveLuckyFarmerUsers * 100).toFixed(2));
            countryLuckyFarmerArrayCount.push(dataChartLuckyFarmerArray[k]);
        }

        //CookinBurger
        const dataTempGeoCookinBurgerParsed = JSON.parse(dataTempGeoCookinBurger.body)
        let countryChartCookinBurgerArray = [], dataChartCookinBurgerArray = [];
        for (let i=0; i<dataTempGeoCookinBurgerParsed.length; i++) {
            countryChartCookinBurgerArray.push(dataTempGeoCookinBurgerParsed[i][0]['stringValue']);
            dataChartCookinBurgerArray.push(dataTempGeoCookinBurgerParsed[i][1]['longValue']);
        }
        const totalGeoActiveCookinBurgerUsers = _.sum(dataChartCookinBurgerArray);

        let dataChartCookinBurgerArrayPercent = [];
        let countryCookinBurgerArray = [], countryCookinBurgerArrayCount = [];
        countrySet = {};
        for (let k=0; k<countryChartCookinBurgerArray.length; k++) {
            countrySet.country = dataTempGeoCookinBurgerParsed[k][0]['stringValue'];
            countrySet.count = dataChartCookinBurgerArray[k];
            countryCookinBurgerArray.push(countrySet);
            countrySet = {};
            dataChartCookinBurgerArrayPercent.push((dataChartCookinBurgerArray[k]/totalGeoActiveCookinBurgerUsers * 100).toFixed(2));
            countryCookinBurgerArrayCount.push(dataChartCookinBurgerArray[k]);
        }

        const activeUsersHomeGeoData = {
            jobTribes: countryJtArray,
            depMarketplace: countryMarketplaceArray,
            luckyFarmer: countryLuckyFarmerArray,
            cookinBurger: countryCookinBurgerArray,
        }
        yield put(setHomeActiveUsersGeoData(activeUsersHomeGeoData));

        countryChartMarketplaceArray = countryChartMarketplaceArray.slice(0, 10);
        dataChartMarketplaceArrayPercent = dataChartMarketplaceArrayPercent.slice(0, 10);

        countryChartJtArray = _.reverse(countryChartJtArray);
        dataChartJtArrayPercent = _.reverse(dataChartJtArrayPercent);
        countryChartMarketplaceArray = _.reverse(countryChartMarketplaceArray);
        dataChartMarketplaceArrayPercent = _.reverse(dataChartMarketplaceArrayPercent);
        countryChartLuckyFarmerArray = _.reverse(countryChartLuckyFarmerArray);
        dataChartLuckyFarmerArrayPercent = _.reverse(dataChartLuckyFarmerArrayPercent);
        countryChartCookinBurgerArray = _.reverse(countryChartCookinBurgerArray);
        dataChartCookinBurgerArrayPercent = _.reverse(dataChartCookinBurgerArrayPercent);

        // raw count array
        dataChartJtArrayCount = _.reverse(dataChartJtArrayCount);
        dataChartNftDepArrayCount = _.reverse(dataChartNftDepArrayCount);
        countryLuckyFarmerArrayCount = _.reverse(countryLuckyFarmerArrayCount);
        countryCookinBurgerArrayCount = _.reverse(countryCookinBurgerArrayCount);

        const activeUsersHomeGeoChartData = {
            jobTribes: [countryChartJtArray, dataChartJtArrayPercent, dataChartJtArrayCount],
            depMarketplace: [countryChartMarketplaceArray, dataChartMarketplaceArrayPercent, dataChartNftDepArrayCount],
            luckyFarmer: [countryChartLuckyFarmerArray, dataChartLuckyFarmerArrayPercent, countryLuckyFarmerArrayCount],
            cookinBurger: [countryChartCookinBurgerArray, dataChartCookinBurgerArrayPercent, countryCookinBurgerArrayCount],
        }
        yield put(setHomeActiveUsersGeoChartData(activeUsersHomeGeoChartData));
    } catch (error) {
        console.log(error);
    }
}

export function* getHomeTierData() {
    let homeDataTier = {}, homeDataTierPrev = {}, homeDataTierLastSeven = {};
    let loading = 'loading';

    homeDataTier.tier1 = loading;
    homeDataTier.tier2 = loading;
    homeDataTier.tier3 = loading;
    yield put(setHomeTierData(homeDataTier));
    yield put(setHomeTierPrevData(homeDataTier));

    const jwtToken = yield call(getJwtToken);

    // Selected Range
    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = dateFrom.replace(/\//g, '-');
    dateTo = dateTo.replace(/\//g, '-');

    // Prev Range
    let datePrevFrom = yield select(startPrevDate);
    let datePrevTo = yield select(endPrevDate);
    datePrevFrom = datePrevFrom.replace(/\//g, '-');
    datePrevTo = datePrevTo.replace(/\//g, '-');

    // Last 7 days
    let dateLastFrom = yield select(lastSevenBeginDate);
    let dateLastTo = yield select(lastSevenEndDate);
    dateLastFrom = dateLastFrom.replace(/\//g, '-');
    dateLastTo = dateLastTo.replace(/\//g, '-');

    // RDS query
    const sqlQueryGetTierUsers = {"query": `SELECT tier, COUNT(*) as count FROM user WHERE update_datetime BETWEEN '${dateFrom}T00:00:00.000Z' AND '${dateTo}T23:59:59.999Z' GROUP BY tier ORDER BY tier`}
    const sqlQueryGetTierUsersPrev = {"query": `SELECT tier, COUNT(*) as count FROM user WHERE update_datetime BETWEEN '${datePrevFrom}T00:00:00.000Z' AND '${datePrevTo}T23:59:59.999Z' GROUP BY tier ORDER BY tier`}
    // update_datetime 2022-10-17T11:06:53.489Z
    const lastSevenTier = yield call(getDataFromSxi, sqlQueryGetTierUsers, ENDPOINT.GET_DATA_SXI, jwtToken);
    const lastSevenTierPrev = yield call(getDataFromSxi, sqlQueryGetTierUsersPrev, ENDPOINT.GET_DATA_SXI, jwtToken);

    homeDataTier.tier1 = lastSevenTier[0]['count'];
    homeDataTier.tier2 = lastSevenTier[1]['count'];
    homeDataTier.tier3 = lastSevenTier[2]['count'];
    yield put(setHomeTierData(homeDataTier));

    homeDataTierPrev.tier1 = lastSevenTierPrev.length > 0 ? lastSevenTierPrev[0]['count'] : '--';
    homeDataTierPrev.tier2 = lastSevenTierPrev.length > 0 ? lastSevenTierPrev[1]['count'] : '--';
    homeDataTierPrev.tier3 = lastSevenTierPrev.length > 0 ? lastSevenTierPrev[2]['count'] : '--';
    yield put(setHomeTierPrevData(homeDataTierPrev));

    // Trend
    const range = moment.range(dateLastFrom, dateLastTo);
    const days = Array.from(range.by('days'));
    const dateArray = days.map(m => m.format('MM/DD/YY'));

    let tier1Array = [], tier2Array =[], tier3Array = [];
    homeDataTierLastSeven.dateRange = dateArray;
    homeDataTierLastSeven.tier1 = tier1Array;
    homeDataTierLastSeven.tier2 = tier2Array;
    homeDataTierLastSeven.tier3 = tier3Array;
    yield put(setHomeTierTrendData(homeDataTierLastSeven));
}

export function* getHomeNftHolderData() {
    let homeNftHolder = {};
    yield put(setHomeNftHolderData('loading'));
    const jwtToken = yield call(getJwtToken);
    const sqlAllAssetsCount = {"query":
        `SELECT COUNT(account.id) AS count FROM token_holder JOIN account ON token_holder.hold_account_id = account.id WHERE account.account_type IN ("USER","CREATOR_USER")`
    };
    const sqlUniqueAssetsCount = {"query":
        `SELECT COUNT(DISTINCT account.id) AS count FROM token_holder JOIN account ON token_holder.hold_account_id = account.id WHERE account.account_type IN ("USER","CREATOR_USER")`
    };
    const [
        dataHolderOffsetTemp,
        dataHolderStatsTemp,
    ] = yield all([
        call(getDataFromSxi, sqlAllAssetsCount, ENDPOINT.GET_DATA_SXI, jwtToken),
        call(getDataFromSxi, sqlUniqueAssetsCount, ENDPOINT.GET_DATA_SXI, jwtToken),
    ]);

    homeNftHolder.tid = dataHolderOffsetTemp[0]['count'];
    homeNftHolder.uid = dataHolderStatsTemp[0]['count'];
    yield put(setHomeNftHolderData(homeNftHolder));
}

export default function* watchMapSettings() {
    yield takeEvery(HOME.GET_HOME_TOTAL_STATS_DATA, handleHomeTotalStatsData);
    yield takeEvery(HOME.GET_HOME_ACQUISITION_DATA, handleHomeAcquisitionData);
    yield takeEvery(HOME.GET_HOME_ACTIVE_USERS_DATA, handleHomeActiveUsersData);
    yield takeEvery(HOME.GET_HOME_NFT_HOLDER_DATA, getHomeNftHolderData);
    yield takeEvery(HOME.GET_HOME_TIER_DATA, getHomeTierData);
    yield takeEvery(PAGE.GET_HOME_PAGE_DATA, handleHomeTotalStatsData);
    yield takeEvery(PAGE.GET_HOME_PAGE_DATA, handleHomeAcquisitionData);
    yield takeEvery(PAGE.GET_HOME_PAGE_DATA, handleHomeActiveUsersData);
    yield takeEvery(PAGE.GET_HOME_PAGE_DATA, getHomeTierData);
}
