import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MapIcon from '@material-ui/icons/Map';
import TocIcon from '@material-ui/icons/Toc';
import moment from "moment-timezone";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import classNames from "classnames";
import {
  setCurrentPage,
  getHomePictreeUser,
  getPictreeDataRegistTrend,
  getPictreeDataCheckInTable,
  setPictreeView,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import Users from "components/PicTree/Home/Users";
import ChartConfig from "utils/ChartConfig";
import LineChartPictreeReg from "components/Charts/LineChartPictreeReg";
import PicTreeTable from "components/Table/PicTreeTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    dataCellTd: {
      fontSize: 13,
      paddingRight: 10,
      paddingBottom: 0,
      paddingLeft: 10,
      textAlign: "right",
      verticalAlign: "middle",
      borderBottom: "1px solid #eee6e6",
      borderRight: "1px solid #eee6e6",
      height: 45,
    },
    lblGameName: {
      color: "#000",
      fontSize: 13,
    },
    tTd: {
      height: 44,
      width: 250,
      paddingLeft: "1rem",
      borderBottom: "1px solid #eee",
      borderCollapse: "collapse",
    },
  })
);

const PicTreeRegistration = ({
  setCurrentPage,
  pageTopLoader,
  getHomePictreeUser,
  getPictreeDataRegistTrend,
  getPictreeDataCheckInTable,
  registTrendData,
  setPictreeView,
  pictreeView,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [view, setView] = useState("registration/chart");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getHomePictreeUser();
    getPictreeDataRegistTrend();
    setPictreeView("registration/chart");
  }, []);

  useEffect(() => {
    if (pictreeView === "registration/chart") {
      getPictreeDataRegistTrend();
    } else if (pictreeView === "registration/table") {
      getPictreeDataCheckInTable();
    }
  }, [pictreeView]);

  const handleView = (view) => {
    setPictreeView(view);
  };

  const getTableLabelColumn = () => {
    return (
      <>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>新規登録</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>PMID連携</div>
          </td>
        </tr>
      </>
    );
  };

  const getDataTable = (data) => {
    return (
      <>
        <tr>
          {data[0]["reg"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["pmid"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
      </>
    );
  };

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      let headerArray = ["ユーザー登録データ"];
      let numOfHeaderData = data[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(data[0]["date"][j]);
      }
      exportData.push(headerArray);

      let countArray = ["新規登録"];
      for (let j = 0; j < numOfHeaderData; j++) {
        countArray.push(data[0]["reg"][j]);
      }
      exportData.push(countArray);

      let upuArray = ["PMID連携"];
      for (let j = 0; j < numOfHeaderData; j++) {
        upuArray.push(data[0]["pmid"][j]);
      }
      exportData.push(upuArray);
    }
    return exportData;
  };


  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">Game</Typography>
              <Typography variant="body1">集客</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <Users />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="flex-end"
        >
          <Grid item></Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <Chip avatar={<ShowChartIcon />} label="チャート" onClick={() => handleView("checkin/chart")}
              variant={pictreeView === "registration/chart" ? "default" : "outlined"}
            />
          </Grid>
        </Grid>

        {pictreeView === "registration/chart" &&
          <>
            <Grid container spacing={1} className={classes.gridSection}>
              <Grid item xs={12} md={12} lg={12}>
                {pageTopLoader ? (
                  <div className={classes.loaderBox}>
                    <img
                      className={classes.loaderBarImg}
                      src={`/static/images/loader-bar.gif`}
                    />
                  </div>
                ) : chartState ? (
                  registTrendData.length > 0 ? (
                    <LineChartPictreeReg
                      legend={ChartConfig.legendPicTreeRegTrend}
                      lblSaveImage={""}
                      xAisData={registTrendData[0]["date"]}
                      seriesData={registTrendData}
                      chartType={"line"}
                    />
                  ) : (
                    <div className={classes.selectGameTxt}>
                      <Chip
                        label="No Data"
                        style={{
                          color: V.textColor,
                          backgroundColor: V.placeholderColor,
                        }}
                      />
                    </div>
                  )
                ) : null}
              </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection}>
              <Grid item xs={12} md={12} lg={12}>
                <PicTreeTable
                  game="PicTree"
                  dataLabel={["新規登録", "PMID連携"]}
                  data={registTrendData}
                  tableKey={["reg", "pmid"]}
                  csvFileName="PicTree - 登録トレンドデータ"
                  getExportData={getExportData}
                  getDataTable={getDataTable}
                  getTableLabelColumn={getTableLabelColumn}
                />
              </Grid>
            </Grid>
          </>
        }
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeRegistration.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  registTrendData: state.pictree.registTrend,
  pictreeView: state.pictree.pictreeView,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreeUser: () => dispatch(getHomePictreeUser()),
  getPictreeDataRegistTrend: () => dispatch(getPictreeDataRegistTrend()),
  getPictreeDataCheckInTable: () => dispatch(getPictreeDataCheckInTable()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeRegistration));
