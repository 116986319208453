import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Grid, IconButton, Chip, Button } from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as C from 'components';
import * as H from 'helper';
import * as V from 'styles/variables';
import EditIcon from '@material-ui/icons/Edit';
import TimeLineIcon from '@material-ui/icons/Timeline';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from "mui-datatables";
import { setCurrentPage, setTableRowSelected, deleteAffiliate, getCampaignSummaryDataByDates, setCampaignSelectedStats } from 'actions';

const CampaignSummary2 = ({
    setCurrentPage,
    tableRowSelected,
    campaignListData,
    setTableRowSelected,
    deleteAffiliate,
    getCampaignSummaryDataByDates,
    setCampaignSelectedStats,
    campaignSelectedStats,
}) => {
    const classes = useStyles();
    const [selectedCampaign, setSelectedCampaign] = useState([]);

    useEffect(() => {
        setCurrentPage('campaignSummary');
        getCampaignSummaryDataByDates();
    }, []);

    const handleRowSelect = (rowSelected) => {
        if (rowSelected.length < 1) {
            setTableRowSelected([]);
            setSelectedCampaign([]);
            setCampaignSelectedStats([]);
        } else {
            let storedRowArray = tableRowSelected;
            let selectedDataIndexArray = [...selectedCampaign];
            _.forEach(rowSelected, function(d, i) {
                // row index
                if (storedRowArray.indexOf(d.index) === -1) {
                    storedRowArray.push(d.index);
                } else {
                    storedRowArray.splice(storedRowArray.indexOf(d.index), 1);
                }

                // data index
                if (selectedDataIndexArray.indexOf(d.dataIndex) === -1) {
                    selectedDataIndexArray.push(d.dataIndex);
                } else {
                    selectedDataIndexArray.splice(selectedDataIndexArray.indexOf(d.dataIndex), 1);
                }
            });

            if (selectedDataIndexArray.length > 0) {
                let campaignPickArray = [];
                _.forEach(selectedDataIndexArray, function(d, i) {
                    campaignPickArray.push(campaignListData[d]);
                });

                const ftimeValue = _.sumBy(campaignPickArray, 'FT');
                const gameVisitValue = _.sumBy(campaignPickArray, 'Conv');
                const regValue = _.sumBy(campaignPickArray, 'Reg');
                const gameCvr = (gameVisitValue/ftimeValue*100).toFixed(2)  === 'Infinity' ? "--" : (gameVisitValue/ftimeValue*100).toFixed(2)+'%'
                const regCvr = (regValue/gameVisitValue*100).toFixed(2) === 'Infinity' ? "--" : (regValue/gameVisitValue*100).toFixed(2)+'%'

                let statsObj = [{
                    firstTime: ftimeValue,
                    gameVisit: gameVisitValue,
                    regist: regValue,
                    convGame: gameCvr,
                    convReg: regCvr
                }];
                setCampaignSelectedStats(statsObj);
            } else {
                setCampaignSelectedStats([]);
            }

            setTableRowSelected(selectedDataIndexArray);
            setSelectedCampaign(selectedDataIndexArray);
        }
    }

    const handleDelete = (deletedRows) => {
        const deletedIndexes = Object.keys(deletedRows.lookup);
        let idsToDelete = [];
        deletedRows.data.map(d => idsToDelete.push(d.dataIndex));
        deleteAffiliate(idsToDelete);

        // update selected row
        let updatedRowArray = tableRowSelected;
        for (let i=0; i<deletedIndexes.length; i++) {
            updatedRowArray.splice(deletedIndexes.indexOf(Number(deletedIndexes[i]), 1));
        }
        setTableRowSelected(updatedRowArray);
    }

    const setChipLabel = (label) => {
        const colorKeyName = 'chipColor'+label.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
        const newLabel = label === "NFT:DEP" ? "PlayMining NFT" : label;
        return <Chip
                    className={classes[colorKeyName]}
                    size="small"
                    label={newLabel}
            />
    }

    const getViewUrlUpdate = (index) => {
        let url = "/campaign/update/"+index;
        return url;
    }

    const getViewUrlView = (index, rid) => {
        let url = "/campaign/view/"+index+"/"+rid;
        return url;
    }

    const getCampaignData = (data) => {
        if (data.length > 0) {
            let allDataArray = [], eachDataArray = [];
            for (let i=0; i<data.length; i++) {
                if (data[i]['Status'] === 'active') {
                    eachDataArray.push(data[i]['AffiliateId']);
                    eachDataArray.push(data[i]['Name']);
                    eachDataArray.push(data[i]['Game'] ? setChipLabel(data[i]['Game']) : setChipLabel("JobTribes"));
                    eachDataArray.push(data[i]['Rid']);
                    eachDataArray.push(H.FormatNumStrHelper.formatAffiliType(data[i]['Type']));
                    eachDataArray.push(data[i]['FT']);
                    if (data[i]['Type'] !== "campaign_game") {
                        eachDataArray.push(data[i]['Conv'] === undefined ? 0 : data[i]['Conv']);
                    } else {
                        eachDataArray.push('--');
                    }
                    eachDataArray.push(data[i]['Reg']);
                    if (data[i]['Type'] !== "campaign_game" && data[i]['Game'] !== "PM Portal") {
                        if (data[i]['Conv'] !== 0 && data[i]['Conv'] !== undefined) {
                            eachDataArray.push(Number(data[i]['Conv']/data[i]['FT'] *100).toFixed(2));
                        } else {
                            eachDataArray.push(0);
                        }
                        if (data[i]['Reg'] !== 0 && data[i]['Reg'] !== undefined) {
                            eachDataArray.push(Number(data[i]['Reg']/data[i]['Conv'] *100).toFixed(2));
                        } else {
                            eachDataArray.push(0);
                        }
                    } else {
                        eachDataArray.push('--');
                        if (data[i]['Reg'] !== 0 && data[i]['Reg'] !== undefined) {
                            eachDataArray.push(Number(data[i]['Reg']/data[i]['FT'] *100).toFixed(2));
                        } else {
                            eachDataArray.push(0);
                        }
                    }
                    eachDataArray.push(H.FormatDateHelper.dateFormatter(data[i]['Timestamp']));
                    allDataArray.push(eachDataArray);
                    eachDataArray = [];
                }
            }
            return allDataArray;
        }
    };

    const getColumns = () => {
        let returningColumn = columns;
        if (localStorage.getItem('access_key') !== process.env.REACT_APP_SUPERUSER) {
            returningColumn.pop();
        }
        return returningColumn;
    }

    const options = {
        filterType: 'checkbox',
        filter: true,
        pagination: true,
        search: true,
        print: false,
        download: true,
        downloadOptions: {
            filename: 'キャンペーン一括データ',
            filterOptions: {
                useDisplayedColumnsOnly: true
            }
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let cleanData = [], tempArray = [], tempObj = {};
            for (let i=0; i<data.length; i++) {
                tempArray = [];
                for (let j=0; j<data[i]['data'].length; j++) {
                    if (j === 2) {
                        tempArray.push(data[i]['data'][j]['props']['label']);
                    } else if (j !== 11 && j !== 12) {
                        tempArray.push(data[i]['data'][j]);
                    }
                }
                tempObj.index = i;
                tempObj.data = tempArray;
                cleanData.push(tempObj);
                tempObj = {};
            }
            return "\uFEFF" + buildHead(columns) + buildBody(cleanData);
        },
        selectableRows: localStorage.getItem('access_key') !== process.env.REACT_APP_SUPERUSER ? false : 'multiple',
        rowsPerPage: 10,
        rowsSelected: tableRowSelected,
        textLabels: {
            body: {
              noMatch: "データを取得中です。。",
              toolTip: "",
            },
            toolbar: {
                search: "検索",
                viewColumns: "列の表示",
                downloadCsv: "CSVダウンロード",
                filterTable: "テーブルのフィルター",
            },
            viewColumns: {
                title: "表示する列",
                titleAria: "テーブルの列の表示/非表示",
            },
            pagination: {
                next: "次のページ",
                previous: "前のページ",
                rowsPerPage: "表示件数",
            },
            filter: {
                all: "全て",
                title: "フィルター",
                reset: "リセット",
            },
            selectedRows: {
                text: "レコードを選択",
                delete: "削除",
                deleteAria: "選択したレコードを削除する",
            }
        },
        onRowsSelect: handleRowSelect,
        onRowsDelete: handleDelete,
        rowsPerPageOptions: [20,30,50,100]
    };

    let columns = [
        {
            name: "ID",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "名前",
            options: {
                filter: false,
                customFilterListRender: v => `名前: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryName}>{value}</div>
                    );
                },
            }
        },
        {
            name: "対象",
            options: {
                filter: true,
                filterOptions: {
                    names: ['JobTribes', 'PM Portal', 'LuckyFarmer', 'PlayMining NFT', 'CookinBurger', 'GraffitiRacer', 'DragonRamen', 'LP Special'],
                    logic(game, filterVal) {
                        // console.log('game:', game.props.label);
                        // console.log('filterVal:', filterVal)
                        const isShow = filterVal.includes(game.props.label);
                        // console.log('isShow:', isShow);
                        return !isShow;// <- opposite to handle show/hide
                    },
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "ADコード",
            options: {
                filter: false,
                customFilterListRender: v => `ADコード: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "タイプ",
            options: {
                filter: true,
                customFilterListOptions: {render: v => `タイプ: ${v}`},
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "①初アクセス",
            options: {
                filter: false,
                customFilterListRender: v => `Destinations: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{H.FormatNumStrHelper.formatCommaThousand(value)}</div>
                    );
                },
            }
        },
        {
            name: "②ゲーム遷移",
            options: {
                filter: false,
                customFilterListRender: v => `Destinations: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let renderValue = value !== '--' ? H.FormatNumStrHelper.formatCommaThousand(value) : '--';
                    return (
                        <div className={classes.summaryCenter}>{renderValue}</div>
                    );
                },
            }
        },
        {
            name: "③CV:PMID登録",
            options: {
                filter: false,
                customFilterListRender: v => `Destinations: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "ゲーム遷移率",
            options: {
                filter: false,
                customHeadRender: ({index, ...column}) => (
                    <th key={index} className={classes.cvrHead}>ゲーム遷移率<br />[②/①]</th>
                ),
                customFilterListRender: v => `CVR: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    value = value === 'Infinity' ? '--' : value;
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "CVR:PMID登録率",
            options: {
                filter: false,
                customHeadRender: ({index, ...column}) => (
                    <th key={index} className={classes.cvrHead}>CVR:PMID登録率<br />[③/②]</th>
                ),
                customFilterListRender: v => `CVR: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "登録日",
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "詳細",
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[4] === 'キャンペーン[ゲーム直]' && tableMeta.rowData[5] === 0 && tableMeta.rowData[7] === 0) {
                        return
                    } else if (tableMeta.rowData[5] === 0 && tableMeta.rowData[6] === 0) {
                        return
                    }
                    return (
                        <Link
                            to={getViewUrlView(tableMeta.rowData[0], tableMeta.rowData[3])}
                            target="_blank">
                            <IconButton
                                aria-label="詳細"
                                //component={ Link }
                                //to={getViewUrlView(tableMeta.rowData[0], tableMeta.rowData[3])}
                            >
                                <TimeLineIcon className={classes.tableRowWithIcon} fontSize="small" />
                            </IconButton>
                        </Link>
                    )
                },
            }
        },
        {
            name: "編集",
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            aria-label="編集"
                            component={ Link }
                            to={getViewUrlUpdate(tableMeta.rowData[0])}
                        >
                            <EditIcon className={classes.tableRowWithIcon} fontSize="small" />
                        </IconButton>
                    )
                },
            }
        },
    ];

    return (
        <Fragment>
            <C.ContentWrapper>
                <Grid container spacing={0} direction="row" style={{ marginBottom: 0 }} justifyContent="space-between">
                    <Grid item>
                    {campaignSelectedStats.length > 0 && tableRowSelected.length > 1 &&
                        <Grid container spacing={0} direction="row" style={{ marginBottom: 8 }} justifyContent="flex-start">
                            <Grid item className={classes.flexItemLeftBox}>初アクセス {campaignSelectedStats[0].firstTime} </Grid>
                            <Grid item className={classes.flexItemLeftBox}>ゲーム遷移 {campaignSelectedStats[0].gameVisit} </Grid>
                            <Grid item className={classes.flexItemLeftBox}>CV:PMID登録 {campaignSelectedStats[0].regist} </Grid>
                            <Grid item className={classes.flexItemLeftBox}>ゲーム遷移率 {campaignSelectedStats[0].convGame} </Grid>
                            <Grid item className={classes.flexItemLeftBox}>CVR:PMID登録率 {campaignSelectedStats[0].convReg} </Grid>
                        </Grid>
                    }
                    </Grid>
                    <Grid item>
                        <Grid container spacing={0} direction="row" style={{ marginBottom: 8 }} justifyContent="flex-end">
                            <Grid item>
                                <C.CalendarRangePicker
                                    mDate={'2020/03/11'}
                                    page="campaignSummary"
                                />
                            </Grid>
                            {localStorage.getItem('access_key') === process.env.REACT_APP_SUPERUSER &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.createAdBtn}
                                    component={ Link }
                                    to="/campaign/create"
                                    startIcon={<AddIcon />}
                                >新規作成</Button>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <MUIDataTable
                        title={"該当キャンペーン："+campaignListData.length}
                        data={getCampaignData(campaignListData)}
                        columns={getColumns()}
                        options={options}
                        className={classes.tableContainer}
                    />
                    </Grid>
                </Grid>
            </C.ContentWrapper>
        </Fragment>
    )
};

CampaignSummary2.propTypes = {
    classes: PropTypes.object,
    campaignListData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
};

const mapStateToProps = state => ({
    tableRowSelected: state.page.tableRowSelected,
    campaignListData: state.campaign.campaignListData,
    campaignSelectedStats: state.campaign.campaignSelectedStats,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setTableRowSelected: (param) => dispatch(setTableRowSelected(param)),
    deleteAffiliate: (param) => dispatch(deleteAffiliate(param)),
    getCampaignSummaryDataByDates: () => dispatch(getCampaignSummaryDataByDates()),
    setCampaignSelectedStats: (param) => dispatch(setCampaignSelectedStats(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSummary2);

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: 'fixed',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            width: '100%',
        },
        createAdBtn: {
            color: '#000',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(1),
            height: 34,
        },
        tableContainer: {
            clear: 'both',
        },
        title: {
            fontSize: 15
        },
        btnCreateWrapper: {
            float: 'right'
        },
        btnCreateAffiliator: {
            color: '#fff',
            marginTop: 16,
            marginRight: '60px',
            float: 'right',
        },
        progress: {
            margin: theme.spacing(),
        },
        chip: {
            color: '#fff',
            backgroundColor: '#1bac91',
            fontSize: 12,
            height: 24
        },
        dialogTitle: {
            fontSize: 16,
        },
        summaryName: {
            lineHeight: '18px',
            textAlign: 'left',
        },
        summaryCenter: {
            textAlign: 'center',
        },
        cvrHead: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 'normal',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        lblLastDataUpdated: {
            float: 'right',
            fontSize: 13,
            color: '#575757',
            paddingBottom: 8,
        },
        tableRowWithIcon: {
            //marginLeft: -16,
        },
        chipColorjobtribes: {
            backgroundColor: V.jobtribes,
            color: '#fff',
        },
        chipColorpuzzle: {
            backgroundColor: V.puzzle,
            color: '#fff',
        },
        chipColorluckyfarmer: {
            backgroundColor: V.luckyfarmer,
            color: '#fff',
        },
        chipColorpmportal: {
            backgroundColor: V.pmportal,
            color: '#fff',
        },
        chipColornftdep: {
            backgroundColor: V.nftdep,
            color: '#fff',
        },
        chipColorcookinburger: {
            backgroundColor: V.cookinburger,
            color: '#fff',
        },
        chipColorgraffitiracer: {
            backgroundColor: V.graffitiracer,
            color: '#fff',
        },
        chipColordragonramen: {
            backgroundColor: V.dragonramen,
            color: '#fff',
        },
        chipColorsoulfusers: {
            backgroundColor: V.soulfusers,
            color: '#fff',
        },
        chipColorroguerollrulers: {
            backgroundColor: V.roguerollrulers,
            color: '#fff',
        },
        chipColorbouncybunny: {
            backgroundColor: V.roguerollrulers,
            color: '#fff',
        },
        chipColorcommunitywars: {
            backgroundColor: V.CommunityWars,
            color: '#fff',
        },
        flexItemLeftBox: {
            marginTop: 28,
            alignSelf: 'center',
            paddingLeft: 8,
            paddingRight: 8,
            borderRight: '1px solid #dcdcdc',
        },
    }),
);