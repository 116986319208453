import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SelectDateRangesPicTree from "components/Select/SelectDateRangesPicTree";
import * as Var from "styles/variables";
import {
  setDateRangePicTreeMission,
  setBtnStateDateFetch,
  setPageNumber,
  getMissionData,
  getMissionRankingData,
} from "actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
      flexGrow: 1,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    container: {
      display: "flex",
      width: 300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignItems: "baseline",
    },
    delimiter: {
      paddingTop: theme.spacing(1),
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      color: Var.btnLabelColor,
    },
    sectionCalendar: {
      display: "flex",
      marginRight: theme.spacing(1),
    },
    sectionDateRange: {
      marginLeft: theme.spacing(0),
    },
  })
);

const CalendarPicTreeMission = ({
  mDate,
  setDateRangePicTreeMission,
  page,
  pictree,
  btnStateDataFetch,
  setBtnStateDateFetch,
  dateRangePicTreeMission,
  currentPage,
  setPageNumber,
  getMissionData,
  getMissionRankingData,
}) => {
  const classes = useStyles();
  const minDate = moment(mDate);
  const maxDate = pictree
    ? moment().tz("Asia/Tokyo").format("YYYY/MM/DD")
    : moment().tz("Asia/Singapore").subtract(1, "days").format("YYYY/MM/DD");
  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [error, setError] = useState([null, null]);

  useEffect(() => {
    // Check daily default setting
    // const currentJstDate = moment().tz("Asia/Tokyo").format('YYYY/MM/DD');
    // handleHomeCalendar(currentJstDate);
  }, []);

  useEffect(() => {
    setDateRangeValue(dateRangePicTreeMission);
  }, [dateRangePicTreeMission]);

  const getDayDiff = (dayBegin, dayEnd) => {
    let diffDays = moment(dayEnd).diff(moment(dayBegin), "days") + 1;
    return diffDays;
  };

  const setRange = (newValue) => {
    if (newValue[0] === null || newValue[1] === null) {
      setBtnStateDateFetch(false);
      return;
    }
    const startDate = moment(newValue[0]).format("YYYY-MM-DD");
    const endDate = moment(newValue[1]).format("YYYY-MM-DD");
    setDateRangePicTreeMission([startDate, endDate]);
    let dayDiff = getDayDiff(startDate, endDate);
    setDateRangeValue([startDate, endDate]);
    setBtnStateDateFetch(true);
  };

  const fetchData = () => {
    setPageNumber(1);
    getMissionData();
    getMissionRankingData();
  };

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <div className={classes.sectionCalendar}>
          <DateRangePicker
            startText="Start"
            endText="End"
            disableHighlightToday={true}
            showToolbar={false}
            allowSameDateSelection={true}
            minDate={minDate}
            maxDate={maxDate}
            value={dateRangeValue}
            onChange={(newValue) => setRange(newValue)}
            onError={([startReason, endReason], [start, end]) => {
              if (startReason === "invalidRange") {
                setError([null, null]);
                return;
              }
              setError([startReason, endReason]);
            }}
            renderInput={(startProps, endProps) => (
              <div className={classes.container}>
                <TextField
                  id="standard-basic"
                  {...startProps}
                  error={Boolean(error[0])}
                  size="small"
                  disabled={page === "pictree-map" ? true : false}
                  helperText=""
                />
                <DateRangeDelimiter>
                  <span className={classes.delimiter}>~</span>
                </DateRangeDelimiter>
                <TextField
                  id="standard-basic"
                  {...endProps}
                  error={Boolean(error[0])}
                  size="small"
                  disabled={page === "pictree-map" ? true : false}
                  helperText=""
                />
              </div>
            )}
          />
          <Button
            disabled={!btnStateDataFetch}
            className={classes.dataBtn}
            variant="contained"
            color="primary"
            onClick={() => fetchData()}
          >
            GO
          </Button>
          {/* <div className={classes.sectionDateRange}>
              <SelectDateRangesPicTree
                  page={page}
                  getDayDiff={getDayDiff}
                  setComparisonDateDiff={setComparisonDateDiff}
                  setPrevDateRange={setPrevDateRange}
                  getPrevDates={getPrevDates}
              />
          </div> */}
        </div>
      </div>
    </div>
  );
};

CalendarPicTreeMission.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  btnStateDataFetch: state.page.btnStateDataFetch,
  dateRangePicTreeMission: state.page.dateRangePicTreeMission,
  currentPage: state.page.currentPage,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartSize,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRangePicTreeMission: (param) => dispatch(setDateRangePicTreeMission(param)),
  setBtnStateDateFetch: (param) => dispatch(setBtnStateDateFetch(param)),
  setPageNumber: (param) => dispatch(setPageNumber(param)),
  getMissionData: () => dispatch(getMissionData()),
  getMissionRankingData: () => dispatch(getMissionRankingData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CalendarPicTreeMission));
