import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter, Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import SidebarItem from "components/Sidebar/SidebarItem";
import SidebarWrapperStyles from "./SidebarWrapperStyles";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class SidebarWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRoute: undefined,
      activeSubRoute: undefined,
    };
  }

  toggleMenu = (index) => {
    if (this.state.activeRoute === index) index = undefined;
    this.setState({ activeRoute: index });
  };

  toggleSubMenu = (index) => {
    if (this.state.activeSubRoute === index) index = undefined;
    this.setState({ activeSubRoute: index });
  };

  render() {
    const {
      classes,
      opened,
      toggleDrawer,
      routes,
      location,
      toggleFullscreen,
      handleLogout,
    } = this.props;
    const { activeRoute, activeSubRoute } = this.state;

    const menu = (
      <List component="div" className={classes.upperDiv}>
        {routes.map((route, index) => {
          const isCurrentPath =
            location.pathname.indexOf(route.path) > -1 ? true : false;
          if (route.sideBar) {
            return (
              <div key={index}>
                {route.category && (
                  <div className={classes.categoryLbl}>{route.category}</div>
                )}
                <SidebarItem
                  index={index}
                  subIndex={this.state.activeSubRoute}
                  route={route}
                  activeRoute={activeRoute}
                  activeSubRoute={activeSubRoute}
                  toggleMenu={this.toggleMenu}
                  toggleSubMenu={this.toggleSubMenu}
                  toggleDrawer={toggleDrawer}
                  currentPath={isCurrentPath}
                  disableMenu={false}
                  opened={opened}
                />
                {route.divider && <Divider />}
              </div>
            );
          }
        })}
      </List>
    );

    return (
      <Fragment>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: opened,
              [classes.drawerClose]: !opened,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: opened,
                [classes.drawerClose]: !opened,
              }),
            }}
            open={opened}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: toggleDrawer,
            }}
          >
            {menu}
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            open={opened}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            disableBackdropTransition={!iOS}
            ModalProps={{
              keepMounted: false,
              className: classes.modal,
              BackdropProps: {
                className: classes.backdrop,
              },
              onBackdropClick: toggleDrawer,
            }}
          >
            {menu}
          </SwipeableDrawer>
        </Hidden>
      </Fragment>
    );
  }
}

SidebarWrapper.prototypes = {
  classes: PropTypes.object.isRequired,
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

const SidebarWithRouter = withRouter(SidebarWrapper);

export default withStyles(SidebarWrapperStyles)(withWidth()(SidebarWithRouter));
