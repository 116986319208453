import { gameConvDetail, chartTrend } from "utils/mockdata/Data";
import { Auth } from "aws-amplify";

const BASE_POI_URL = process.env.REACT_APP_API_POI_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL_DATA = process.env.REACT_APP_API_BASE_URL_DATA;
const API_KEY_PICTREE_DATA = process.env.REACT_APP_API_KEY_PICTREE_DATA;

const HEADER = {
  Authorization: localStorage.getItem("idToken"),
  "Content-Type": "application/json",
};

export const getCurrentDepPrice = async (Endpoint, token) => {
  // const response = await fetch(`${API_BASE_URL}${Endpoint}`,
  const response = await fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=deapcoin&vs_currencies=usd&include_24hr_change=true`,
    {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getCurrentDepExchangeRate = async () => {
  const response = await fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=deapcoin&vs_currencies=usd%2Cjpy%2Csgd`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getJwtToken = async () => {
  const currentSession = await Auth.currentSession();
  const response = await fetch(
    "https://cognito-idp." +
      process.env.REACT_APP_COGNITO_REGION +
      ".amazonaws.com/",
    {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: currentSession.refreshToken.token,
          //SECRET_HASH: "your_secret", // In case you have configured client secret
        },
      }),
    }
  );
  const data = await response.json();
  localStorage.setItem("idToken", data.AuthenticationResult.IdToken);
  //console.log('=======', data.AuthenticationResult.IdToken)
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data.AuthenticationResult.IdToken;
};

export const getCurrentUserData = async () => {
  const currentSession = await Auth.currentSession();
  const response = await fetch(
    "https://cognito-idp." +
      process.env.REACT_APP_COGNITO_REGION +
      ".amazonaws.com/",
    {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
        "Content-Type": "application/x-amz-json-1.1",
        "Content-Length": 1162, // Access Token bytes length,
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        AccessToken: currentSession.accessToken.jwtToken,
      }),
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  localStorage.setItem(
    "access_key",
    data["UserAttributes"].find(function (obj) {
      return obj.Name === "custom:access_key";
    }).Value
  );
  return data["UserAttributes"].find(function (obj) {
    return obj.Name === "custom:access_key";
  }).Value;
};

export const getAllBIListUsers = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchPoiSearchData = async (param, Endpoint) => {
  const paramTxt =
    "?client_key=" +
    localStorage.getItem("client_id") +
    "&poi_fields=" +
    param.field +
    "&geojson=true";
  const postData = param.postData;
  const response = await fetch(`${BASE_POI_URL}${Endpoint}${paramTxt}`, {
    method: "POST",
    headers: HEADER,
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const createAffiliator = async (postData, Endpoint, token) => {
  //const paramTxt = 'dea'; //localStorage.getItem('client_id');
  const response = await fetch(
    `${API_BASE_URL}${Endpoint}`, // /${paramTxt}
    {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const updateAffiliator = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchAffiliators = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

// export const fetchAffiliatorsByKey = async (Endpoint) => {
//     const response = await fetch(`${API_BASE_URL}${Endpoint}`,
//         {
//             method: 'GET',
//             headers: HEADER,
//         });
//     const data = await response.json();
//     if (response.status >= 400) {
//         throw new Error(data.errors);
//     }
//     return data;
// }

export const fetchCampaignGroup = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchUsersDataByAid = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  // const data = gameConvDetail;
  return data;
};

export const fetchTrendDataByAid = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchTrendDataByAidGA = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  let returnData = [],
    dataObj = {};

  if (data !== null) {
    for (let i = 0; i < data.length; i++) {
      dataObj.FT = 1;
      dataObj.rid = data[i]["dimensions"][0];
      let datum =
        data[i]["dimensions"][1].substring(0, 4) +
        "-" +
        data[i]["dimensions"][1].substring(4, 6) +
        "-" +
        data[i]["dimensions"][1].substring(6, 8) +
        " " +
        data[i]["dimensions"][1].substring(8, 10) +
        ":" +
        data[i]["dimensions"][1].substring(10, 12) +
        ":" +
        data[i]["dimensions"][1].substring(12, 14);
      // console.log('datum:', datum);
      let date = new Date(datum);
      dataObj.Timestamp = date.getTime();
      dataObj.Country = data[i]["dimensions"][2];
      dataObj.City = data[i]["dimensions"][3];
      dataObj.Lang = data[i]["dimensions"][4];
      dataObj.Ref = data[i]["dimensions"][5];
      returnData.push(dataObj);
      dataObj = {};
    }
  } else {
    console.log("ga returned null, should show Data is not available");
  }
  return returnData;
};

export const deleteAffiliator = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchNodeGaEventData = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const fetchEsJtcbData = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getHomeTotalStats = async (Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getTotalPmid = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getHomeTotalUsers = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    // mode: 'cors',
    // cache: 'no-cache',
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getHomePrevTierUsers = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getUsersTierTrend = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getDataFromRDS = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getDataFromElasticSearch = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getDataFromSxi = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

export const getDataFromKpiDash = async (postData, Endpoint) => {
  const response = await fetch(`${API_BASE_URL_DATA}${Endpoint}`, {
    method: "POST",
    headers: {
      "X-Api-Key": API_KEY_PICTREE_DATA,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  });
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};
