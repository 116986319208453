
import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { getJwtToken, getDataFromKpiDash, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";

import {
  setPageTopLoader,
  setRewardCoinSummary,
  setRewardCoinData,
  setRewardCoinRankingData,
} from 'actions';

export const dateRange = (state) => state.page.dateRangePicTree;
export const startDate = state => state.page.dateRangePicTree[0];
export const endDate = state => state.page.dateRangePicTree[1];

export function* handleRewardCoinSummaryData() {
  try {
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const sqlQuery = {
      query: `
        SELECT SUM(bch.amount) AS amount, COUNT(*) AS count, COUNT(DISTINCT(bce.player_id)) AS players
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        JOIN player_state ps ON bce.player_id = ps.player_id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bch.coin_type in ("REWARD") AND bch.amount > 0;
      `,
      database: "KpiDashboard",
      type: "list",
    }

    const [dataTemp] =
    yield all([
      call(getDataFromSxi, sqlQuery, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);
    const summaryData = dataTemp;
    const rewardSummaryData = {
      amount: summaryData[0]["amount"],
      count: summaryData[0]["count"],
      players: summaryData[0]["players"],
      avgByCount: summaryData[0]["amount"] / summaryData[0]["count"],
      avgByPlayers: summaryData[0]["amount"] / summaryData[0]["players"],
    };
    yield put(setRewardCoinSummary(rewardSummaryData));
  } catch (error) {
    console.log(error);
  }
}

export function* handleRewardCoinData() {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const sqlQuery = {
      query: `
        SELECT
          bch.amount AS reward_coin_amount,
          bce.player_id AS player_id,
          ps.name AS name,
          ps.team_code,
          DATE_FORMAT(CONVERT_TZ(bch.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d %H:%i:%s') AS occurred_datetime_jst
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        JOIN player_state ps ON bce.player_id = ps.player_id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bch.coin_type in ("REWARD") AND bch.amount > 0
        ORDER BY bch.occurred_datetime DESC;
      `
    }

    const [dataRankingTemp] =
    yield all([
      call(getDataFromKpiDash, sqlQuery, ENDPOINT.GET_DATA_API),
    ]);
    const rankingData = JSON.parse(dataRankingTemp.body);
    let rankingTableData = [];
    _.each(rankingData, function(d, index) {
      rankingTableData.push([
        index+1,
        d.player_id,
        d.name,
        d.team_code,
        d.reward_coin_amount,
        d.occurred_datetime_jst,
      ]);
    })
    yield put(setRewardCoinData(rankingTableData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleRewardCoinRankingData() {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const sqlQueryRanking = {
      query: `
        SELECT SUM(bch.amount) AS reward_coin_amount, COUNT(*) AS count, bce.player_id AS player_id, ps.name AS name, ps.team_code
        FROM balance_change_history bch
        JOIN balance_change_event bce ON bch.source_event_id = bce.id
        JOIN player_state ps ON bce.player_id = ps.player_id
        WHERE CONVERT_TZ(bch.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND bch.coin_type in ("REWARD") AND bch.amount > 0
        GROUP BY player_id
        ORDER BY reward_coin_amount DESC;
      `,
      database: "KpiDashboard",
      type: "list",
    }

    const [dataRankingTemp] =
    yield all([
      call(getDataFromSxi, sqlQueryRanking, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);
    // const rankingData = JSON.parse(dataRankingTemp.body);
    const rankingData = dataRankingTemp;
    const totalReward = _.sumBy(rankingData, 'reward_coin_amount');
    let rankingTableData = [];
    _.each(rankingData, function(d, index) {
      const ratio = (d.reward_coin_amount / totalReward * 100).toFixed(2);
      rankingTableData.push([
        index+1,
        d.player_id,
        d.name,
        d.team_code,
        d.count,
        d.reward_coin_amount,
        ratio,
      ]);
    })
    yield put(setRewardCoinRankingData(rankingTableData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(PICTREE.GET_REWARD_COIN_SUMMARY_DATA, handleRewardCoinSummaryData);
  yield takeEvery(PICTREE.GET_REWARD_COIN_DATA, handleRewardCoinData);
  yield takeEvery(PICTREE.GET_REWARD_COIN_RANKING_DATA, handleRewardCoinRankingData);
}