import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import classNames from "classnames";
import {
  setCurrentPage,
  getHomeTotalStatsData,
  getHomeAcquisitionData,
  getHomeActiveUsersData,
  getHomeTierData,
  getHomeNftHolderData,
} from "actions";
import * as C from "components";
import * as H from "helper";
import * as V from "styles/variables";

const Home = ({
  classes,
  setCurrentPage,
  getHomeTotalStatsData,
  getHomeAcquisitionData,
  getHomeActiveUsersData,
  getHomeTierData,
  getHomeNftHolderData,
  totalRegStats,
  dateRange,
  mauDateRange,
  wauDateRange,
  nftHoldersData,
}) => {
  const [expanded, setExpanded] = React.useState(true);

  useEffect(() => {
    setCurrentPage("home");
    getHomeTotalStatsData();
    getHomeAcquisitionData();
    getHomeActiveUsersData();
    getHomeTierData();
    getHomeNftHolderData();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getStatValue = (data) => {
    if (data === "loading" || data === "-") {
      return "Loading..";
    } else {
      return H.FormatNumStrHelper.formatCommaThousand(data);
    }
  };

  const getNftHolderValue = (data, type) => {
    if (data === "loading") {
      return "Loading..";
    } else {
      if (data && data.uid != undefined && type == "tid") {
        return H.FormatNumStrHelper.formatCommaThousand(data.tid);
      } else if (data && data.uid != undefined && type == "uid") {
        return H.FormatNumStrHelper.formatCommaThousand(data.uid);
      } else if (data && data.uid != undefined && type == "price") {
        return H.FormatNumStrHelper.formatCommaThousand(data.price) + " DEP";
      }
    }
  };

  const getActualRegValue = (data) => {
    if (data === "loading" || data === "-") {
      return "Loading..";
    } else {
      let actualReg = totalRegStats.org - totalRegStats.fakeReg;
      return H.FormatNumStrHelper.formatCommaThousand(actualReg);
    }
  };

  const getRatioToAll = (num) => {
    if (num === "-" || num === "loading") return "--%";
    return ((num / totalRegStats.gamePmid) * 100).toFixed(2) + "%";
  };

  const getRatioAdOrg = (num) => {
    if (num === "-" || num === "loading") return "--%";
    let actualReg = totalRegStats.org - totalRegStats.fakeReg;
    return ((num / actualReg) * 100).toFixed(2) + "%";
  };

  const getActualRatioToAll = (num) => {
    if (num === "-" || num === "loading") return "--%";
    let actualReg = totalRegStats.gamePmid - totalRegStats.fakeReg;
    return ((actualReg / totalRegStats.gamePmid) * 100).toFixed(2) + "%";
  };

  const getRatioTier = (num) => {
    if (num === "-" || num === "loading") return "--%";
    const totalTiers =
      totalRegStats.tier1 + totalRegStats.tier2 + totalRegStats.tier3;
    return ((num / totalTiers) * 100).toFixed(2) + "%";
  };

  const setChipLabel = (label, data, chipLbl) => {
    const colorKeyName = "chipColorStats" + chipLbl;
    return (
      <Chip
        className={classNames(classes[colorKeyName], classes.chipLabel)}
        label={label + ": " + data}
      />
    );
  };

  const setChipLabelWithPercent = (label, data1, data2, chipLbl) => {
    const colorKeyName = "chipColorStats" + chipLbl;
    return (
      <Chip
        className={classNames(classes[colorKeyName], classes.chipLabel)}
        label={label + ": " + data1 + " (" + data2 + ")"}
      />
    );
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ paddingTop: 8, paddingRight: 16 }}>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item className={classes.sumStats}>
                全PMID登録: {getStatValue(totalRegStats.gamePmid, "Total")}
              </Grid>
              <Grid item className={classes.sumStats}>
                ユーザー保有NFT総数: {getNftHolderValue(nftHoldersData, "tid")}
              </Grid>
              {/* <Grid item className={classes.sumStats}>ユーザー保有NFT総額: {getNftHolderValue(nftHoldersData, 'price')}</Grid> */}
              <Grid item className={classes.sumStats}>
                NFTホルダーPMID数: {getNftHolderValue(nftHoldersData, "uid")}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item className={classes.flexInnerItem}>
                {setChipLabelWithPercent(
                  "Tier 1",
                  getStatValue(totalRegStats.tier1),
                  getRatioTier(totalRegStats.tier1),
                  "Tier1"
                )}
              </Grid>
              <Grid item className={classes.flexInnerItem}>
                {setChipLabelWithPercent(
                  "Tier 2",
                  getStatValue(totalRegStats.tier2),
                  getRatioTier(totalRegStats.tier2),
                  "Tier2"
                )}
              </Grid>
              <Grid item className={classes.flexInnerItem}>
                {setChipLabelWithPercent(
                  "Tier 3",
                  getStatValue(totalRegStats.tier3),
                  getRatioTier(totalRegStats.tier3),
                  "Tier3"
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2020/09/28"} page="home" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.flexContainerNotFix}>
              <div className={classes.flexItemLeftBox}>
                Organic登録: {getStatValue(totalRegStats.org, "Org")} (
                {getRatioToAll(totalRegStats.org)})
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        <u>広告経由以外、Organic</u>
                        でのPMID登録合計。％は全PMID登録に占める割合
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <HelpIcon className={classes.iconTooltip} fontSize="small" />
                </HtmlTooltip>
              </div>
              <div className={classes.flexItemLeftBox}>
                Fake見込み登録: {getStatValue(totalRegStats.fakeReg, "Org")} (
                {getRatioToAll(totalRegStats.fakeReg)})
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        下記、<u>捨てメールアドレス利用</u>
                        での登録総数。％は全PMID登録に占める割合
                      </Typography>
                      <ul>
                        <li>promail1.net</li>
                        <li>rcmails.com</li>
                        <li>mailkept.com</li>
                        <li>isecv.com</li>
                        <li>mailsac.com</li>
                        <li>relxv.com</li>
                        <li>qq.com</li>
                      </ul>{" "}
                      <br />
                      {"アクセスは主にドイツ、オランダなど欧州に集中している"}
                    </React.Fragment>
                  }
                >
                  <HelpIcon className={classes.iconTooltip} fontSize="small" />
                </HtmlTooltip>
              </div>
              <div className={classes.flexItemLeftBox}>
                実質見込み登録: {getActualRegValue(totalRegStats.fakeReg)} (
                {getActualRatioToAll(totalRegStats.fakeReg)})
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        <u>捨てメールアドレスを除外</u>
                        したPMID登録合計。％は全PMID登録に占める割合
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <HelpIcon className={classes.iconTooltip} fontSize="small" />
                </HtmlTooltip>
              </div>
              <div className={classes.flexItemLeftBox}>
                広告登録: {getStatValue(totalRegStats.ads, "Ads")} (
                {getRatioAdOrg(totalRegStats.ads)})
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">
                        実質登録数 {getActualRegValue(totalRegStats.fakeReg)}{" "}
                        へ占める割合
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <HelpIcon className={classes.iconTooltip} fontSize="small" />
                </HtmlTooltip>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* Acquisition Panel */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardHeadSection}>
                  <Typography
                    className={classes.cardTitle}
                    variant="h6"
                    component="h2"
                    gutterBottom
                  >
                    PlayMining 全体
                  </Typography>
                  {/* <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div> */}
                </div>
                <div className={classes.cardContentSection}>
                  <C.PmAcquisition />
                </div>
              </CardContent>
              <CardActions
                disableSpacing={true}
                className={classes.cardActionSection}
              >
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  size="small"
                >
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <div className={classes.cardContentSection}>
                    <C.PmExpand />
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        </Grid>

        {/* Each asset */}
        <Grid container spacing={3}>
          {moment(dateRange[1]).isAfter("2021-05-25") ? (
            <Grid item sm={12} lg={6}>
              <Card>
                <CardContent>
                  <div className={classes.cardHeadSection}>
                    <Typography
                      className={classes.cardTitle}
                      variant="h6"
                      component="h2"
                      gutterBottom
                    >
                      PlayMining NFT
                    </Typography>
                    <div className={classes.titleAction}>
                      <span className={classes.dataFetchDateLbl}>
                        データ取得開始日:2021/5/26
                      </span>
                      {/* <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton> */}
                    </div>
                  </div>
                  <div className={classes.cardContentSection}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="depMarketplace"
                          title={
                            "DAU (" + moment(dateRange[1]).format("M/D") + ")"
                          }
                          tip={
                            "Daily Active Users<br />DAU算出日 : " +
                            moment(dateRange[1]).format("M/D")
                          }
                          type="dau"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="depMarketplace"
                          title={
                            "WAU (" +
                            moment(wauDateRange[0]).format("M/D") +
                            " - " +
                            moment(wauDateRange[1]).format("M/D") +
                            ")"
                          }
                          tip={
                            "Weekly Active Users</br >WAU算出の7日 : " +
                            moment(wauDateRange[0]).format("M/D") +
                            " - " +
                            moment(wauDateRange[1]).format("M/D")
                          }
                          type="wau"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="depMarketplace"
                          title={
                            "MAU (" +
                            moment(mauDateRange[0]).format("M/D") +
                            " - " +
                            moment(mauDateRange[1]).format("M/D") +
                            ")"
                          }
                          tip={
                            "Monthly Active Users</br >MAU算出の30日 : " +
                            moment(mauDateRange[0]).format("M/D") +
                            " - " +
                            moment(mauDateRange[1]).format("M/D")
                          }
                          type="mau"
                          lastItem={true}
                        />
                      </Grid>
                      <Grid item sm={12} lg={7}>
                        <C.HomeCountryMap
                          source="depMarketplace"
                          width="100%"
                          height="350px"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={5}>
                        <C.BarChart
                          color={V.mapColor}
                          game={"depMarketplace"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <Grid item sm={12} lg={6}>
            <Card>
              <CardContent>
                <div className={classes.cardHeadSection}>
                  <Typography
                    className={classes.cardTitle}
                    variant="h6"
                    component="h2"
                    gutterBottom
                  >
                    JobTribes
                  </Typography>
                  <div className={classes.titleAction}>
                    <span className={classes.dataFetchDateLbl}>
                      データ取得開始日:2020/12/22
                    </span>
                  </div>
                </div>
                <div className={classes.cardContentSection}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="jobTribes"
                        title={
                          "DAU (" + moment(dateRange[1]).format("M/D") + ")"
                        }
                        tip={
                          "Daily Active Users<br />DAU算出日 : " +
                          moment(dateRange[1]).format("M/D")
                        }
                        type="dau"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="jobTribes"
                        title={
                          "WAU (" +
                          moment(wauDateRange[0]).format("M/D") +
                          " - " +
                          moment(wauDateRange[1]).format("M/D") +
                          ")"
                        }
                        tip={
                          "Weekly Active Users</br >WAU算出の7日 : " +
                          moment(wauDateRange[0]).format("M/D") +
                          " - " +
                          moment(wauDateRange[1]).format("M/D")
                        }
                        type="wau"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="jobTribes"
                        title={
                          "MAU (" +
                          moment(mauDateRange[0]).format("M/D") +
                          " - " +
                          moment(mauDateRange[1]).format("M/D") +
                          ")"
                        }
                        tip={
                          "Monthly Active Users<br />MAU算出の30日 : " +
                          moment(mauDateRange[0]).format("M/D") +
                          " - " +
                          moment(mauDateRange[1]).format("M/D")
                        }
                        type="mau"
                        lastItem={true}
                      />
                    </Grid>
                    <Grid item sm={12} lg={7}>
                      <C.HomeCountryMap
                        source="jobtribes"
                        width="100%"
                        height="350px"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                      <C.BarChart color={V.mapColor} game={"jobTribes"} />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          </Grid>
          {moment(dateRange[1]).isAfter("2022-08-14") ? (
            <Grid item sm={12} lg={6}>
              <Card>
                <CardContent>
                  <div className={classes.cardHeadSection}>
                    <Typography
                      className={classes.cardTitle}
                      variant="h6"
                      component="h2"
                      gutterBottom
                    >
                      Cookin' Burger
                    </Typography>
                    <div className={classes.titleAction}>
                      <span className={classes.dataFetchDateLbl}>
                        データ取得開始日:2022/8/15
                      </span>
                    </div>
                  </div>
                  <div className={classes.cardContentSection}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="cookinBurger"
                          title={
                            "DAU (" + moment(dateRange[1]).format("M/D") + ")"
                          }
                          tip={
                            "Daily Active Users<br />DAU算出日 : " +
                            moment(dateRange[1]).format("M/D")
                          }
                          type="dau"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="cookinBurger"
                          title={
                            "WAU (" +
                            moment(wauDateRange[0]).format("M/D") +
                            " - " +
                            moment(wauDateRange[1]).format("M/D") +
                            ")"
                          }
                          tip={
                            "Weekly Active Users<br />WAU算出の7日 : " +
                            moment(wauDateRange[0]).format("M/D") +
                            " - " +
                            moment(wauDateRange[1]).format("M/D")
                          }
                          type="wau"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <C.ProductStats
                          game="cookinBurger"
                          title={
                            "MAU (" +
                            moment(mauDateRange[0]).format("M/D") +
                            " - " +
                            moment(mauDateRange[1]).format("M/D") +
                            ")"
                          }
                          tip={
                            "Monthly Active Users<br />MAU算出の30日 : " +
                            moment(mauDateRange[0]).format("M/D") +
                            " - " +
                            moment(mauDateRange[1]).format("M/D")
                          }
                          type="mau"
                          lastItem={true}
                        />
                      </Grid>
                      <Grid item sm={12} lg={7}>
                        <C.HomeCountryMap
                          source="cookinBurger"
                          width="100%"
                          height="350px"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={5}>
                        <C.BarChart color={V.mapColor} game={"cookinBurger"} />
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
          <Grid item sm={12} lg={6}>
            <Card>
              <CardContent>
                <div className={classes.cardHeadSection}>
                  <Typography
                    className={classes.cardTitle}
                    variant="h6"
                    component="h2"
                    gutterBottom
                  >
                    Lucky Farmer
                  </Typography>
                  <div className={classes.titleAction}>
                    <span className={classes.dataFetchDateLbl}>
                      データ取得開始日:2022/11/14
                    </span>
                  </div>
                </div>
                <div className={classes.cardContentSection}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="luckyFarmer"
                        title={
                          "DAU (" + moment(dateRange[1]).format("M/D") + ")"
                        }
                        tip={
                          "Daily Active Users<br />DAU算出日 : " +
                          moment(dateRange[1]).format("M/D")
                        }
                        type="dau"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="luckyFarmer"
                        title={
                          "WAU (" +
                          moment(wauDateRange[0]).format("M/D") +
                          " - " +
                          moment(wauDateRange[1]).format("M/D") +
                          ")"
                        }
                        tip={
                          "Weekly Active Users<br />WAU算出の7日 : " +
                          moment(wauDateRange[0]).format("M/D") +
                          " - " +
                          moment(wauDateRange[1]).format("M/D")
                        }
                        type="wau"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <C.ProductStats
                        game="luckyFarmer"
                        title={
                          "MAU (" +
                          moment(mauDateRange[0]).format("M/D") +
                          " - " +
                          moment(mauDateRange[1]).format("M/D") +
                          ")"
                        }
                        tip={
                          "Monthly Active Users<br />MAU算出の30日 : " +
                          moment(mauDateRange[0]).format("M/D") +
                          " - " +
                          moment(mauDateRange[1]).format("M/D")
                        }
                        type="mau"
                        lastItem={true}
                      />
                    </Grid>
                    <Grid item sm={12} lg={7}>
                      <C.HomeCountryMap
                        source="luckyFarmer"
                        width="100%"
                        height="350px"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                      <C.BarChart color={V.mapColor} game={"luckyFarmer"} />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

Home.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  totalRegStats: state.home.totalRegStats,
  dateRange: state.page.dateRange,
  mauDateRange: state.page.mauDateRange,
  wauDateRange: state.page.wauDateRange,
  nftHoldersData: state.home.nftHoldersData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomeTotalStatsData: () => dispatch(getHomeTotalStatsData()),
  getHomeAcquisitionData: () => dispatch(getHomeAcquisitionData()),
  getHomeActiveUsersData: () => dispatch(getHomeActiveUsersData()),
  getHomeTierData: () => dispatch(getHomeTierData()),
  getHomeNftHolderData: () => dispatch(getHomeNftHolderData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(Home));
